<template>
  <div
    class="mu-flex-box"
    style="width: 100%; height: 100%;"
    align-items="center"
    justify-content="center">
    <div class="mc-page-spinner_container">
      <div :style="styles" class="mc-page-spinner mc-page-spinner_-mc-page-spinner_hexagon">
        <div :style="innerStyles" class="mc-page-spinner_inner">
          <ul class="mc-page-spinner_hexagon-container">
            <li class="mc-page-spinner_hexagon hex_1" />
            <li class="mc-page-spinner_hexagon hex_2" />
            <li class="mc-page-spinner_hexagon hex_3" />
            <li class="mc-page-spinner_hexagon hex_4" />
            <li class="mc-page-spinner_hexagon hex_5" />
            <li class="mc-page-spinner_hexagon hex_6" />
            <li class="mc-page-spinner_hexagon hex_7" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'McPageLoadingAnimation',
    props: {
      size: {
        default: '64px'
      }
    },
    computed: {
      innerStyles () {
        const size = parseInt(this.size)
        return {
          transform: 'scale(' + (size / 164) + ')'
        }
      },
      styles () {
        return {
          width: this.size,
          height: this.size
        }
      }
    }
  }
</script>
