<template>
  <div class="mc-fs-upload">
    <form class="mc-file-hidden">
      <input class="mc-file-reset" type="reset">
      <input
        ref="fileInput"
        class="mc-file mc-file-hidden"
        type="file"
        name="file"
        accept="image/jpeg, image/png"
        :multiple="multiple"
        @change="handleFiles"
        @click="resetInput">
    </form>
    <mu-button
      button-style="link"
      class="mc-file-upload-button"
      @click="triggerFileInput">
      +
    </mu-button>
    <div v-if="false" class="mc-file-progress">
      <div class="mc-file-progress-mask" />
      <div class="mc-file-progress-title">
        <div>文件上传中...</div>
        <div>{{ percentText }}</div>
      </div>
      <div class="mc-file-progress-wrapper">
        <div
          class="mc-file-progress-bar"
          :style="{ width: `${percent}%`}" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, shallowReactive, inject } from 'vue'
  import uniqueId from '@utils/unique-id'
  import { uploadFile, getFileBaseInfo } from '@utils/file-service'

  const props = defineProps({
    product: {
      type: String,
      default: 'platform'
    },
    moduleName: {
      type: String,
      default: 'platform'
    },
    allowedExtensions: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png']
    },
    multiple: {
      type: Boolean,
      default: true
    },
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024 // 默认限制为 5MB
    },
    maxFileCount: {
      type: Number,
      default: 5
    },
    beforeUpload: {
      type: Function,
      default: null
    },
    onUploadError: {
      type: Function,
      default: null
    },
    onUploadSuccess: {
      type: Function,
      default: null
    },
    onUploadProgress: {
      type: Function,
      default: null
    },
    messageBox: {
      type: Object,
      default: () => ({})
    }
  })

  const emit = defineEmits(['cancelFileSelection'])
  const fileInput = ref(null)
  const files = ref([])
  const fileMap = shallowReactive({})
  const total = ref(0)
  const percent = ref(0)
  const showProgress = ref(false)
  const currentKey = ref(null)
  const currentIndex = ref(0)
  const totalFileCount = ref(0)
  const context = inject('context')

  const percentText = computed(() => percent.value > 100 ? '100%' : `${percent.value}%`)

  function validateFile (file) {
    const fileSize = file.size
    const fileExtension = file.name.split('.').pop().toLowerCase()

    if (fileSize > props.maxFileSize) {
      const msg = `文件大小超过限制（${props.maxFileSize / 1024 / 1024}MB）`
      if (props.messageBox.alert) {
        props.messageBox.alert(msg)
      } else {
        console.error(msg)
      }

      return false
    }

    if (!props.allowedExtensions.includes(fileExtension)) {
      const msg = `不支持的文件类型（仅支持 ${props.allowedExtensions.join(', ')}）`
      if (props.messageBox.alert) {
        props.messageBox.alert(msg)
      } else {
        console.error(msg)
      }

      return false
    }

    return true
  }

  async function handleFiles (e) {
    if (files.value.length >= props.maxFileCount) {
      if (props.messageBox.alert) {
        props.messageBox.alert(`文件数量超过限制（${props.maxFileCount}）`)
      }
      return
    }

    const fileList = e.target.files

    // 没有选择文件或点关闭、取消按钮
    if (!fileList || !fileList.length) {
      emit('cancelFileSelection')
      return
    }

    total.value = 0
    totalFileCount.value = fileList.length
    for (let i = 0; i < fileList.length; i++) {
      currentIndex.value = i + 1
      const file = fileList[i]
      const fileId = uniqueId()

      if (!validateFile(file)) {
        break
      }

      file.progress = 0
      file.fileId = fileId
      files.value.push(file)
      fileMap[fileId] = file
      total.value += file.size
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i]
      if (props.beforeUpload) {
        props.beforeUpload(file)
      }
      await uploadData(file)
    }

    percent.value = 100
    showProgress.value = false
  }

  function resetInput () {
    fileInput.value.value = ''
  }

  function triggerFileInput () {
    fileInput.value.click()
  }

  async function uploadData (file) {
    const userId = context.userId
    const { fileName, fileKey } = getFileBaseInfo(file, userId)

    const params = {
      product: props.product,
      moduleName: props.moduleName,
      tenantIgnore: true,
      tempFile: true,
      forPublic: false,
      key: fileKey,
      fileObject: file,
      fileName,
      fileType: file.type
    }

    showProgress.value = true
    await uploadFile({
      ...params,
      success: data => {
        if (props.onUploadSuccess) {
          props.onUploadSuccess(data, file, total.value)
        }
      },
      error: msg => {
        console.warn('upload fail: ' + msg)
        if (props.onUploadFail) this.onUploadFail(msg, fileKey)
      },
      onUploadProgress: e => {
        percent.value = (e.loaded * 100 / total.value).toFixed(2)
        if (props.onUploadProgress) {
          props.onUploadProgress(e.total, e.loaded, currentKey)
        }
      },
      onCancelToken: cancel => {
        fileMap[file.fileId].cancel = cancel
      }
    })
  }
</script>

<style lang="scss">
.mc-file-hidden {
    display: none;
  }

.mc-fs-upload {
  --mu-border-color: #ebecf0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border: 1px dashed var(--mu-border-color);

  &:hover {
    background-color: #d8d8d8;
    border-color: var(--mu-primary-color);
  }

  .mc-file-upload-button {
    width: 100%;
    height: 100%;
    font-size: 32px;
    color: var(--mu-primary-color);
  }

  .mc-file-progress {
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  .mc-file-progress-mask {
    position: absolute;

    width: 100%;
    height: 100%;

    opacity: 0.70;
    background: #333333;
  }

  .mc-file-progress-title {
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 180px;

    color: #fff;
  }

  .mc-file-progress-wrapper {
    z-index: 100;

    overflow: hidden;

    width: 180px;
    height: 16px;

    border: 1px solid #38d9a9;
    border-radius: 30px;
  }

  .mc-file-progress-bar {
    height: 14px;

    background: repeating-linear-gradient(
      120deg,
      transparent,
      transparent 4px,
      #38d9a9 4px,
      #38d9a9 8px);
    border-radius: 30px;

    transition: 0.4s linear;
    transition-property: width, background-color;
  }
}
</style>
