<template>
  <div
    ref="pageMenu"
    class="mc-sidebar"
    :floating="isFloating || null"
    :collapsed="isCollapsed || null"
    :style="sidebarStyle">
    <div
      class="mc-sidebar_container"
      :overflow="isCollapsed ? null : 'hidden'"
      :style="{ width: contentWidth }"
      @mouseleave="onSidebarMouseLeave">
      <div
        v-if="headerVisible"
        class="mc-sidebar_header">
        <slot v-if="!isCollapsed" name="header" />
        <a
          v-if="collapseBtnPosition === 'top'"
          class="mc-sidebar_collapse-button mc-button-like"
          @mouseover="onCollapseBtnMouseOver"
          @click="toggleCollapse">
          <mu-icon :icon="menuIcon" />
        </a>
      </div>
      <div class="mc-sidebar_body">
        <div
          v-show="!isCollapsed"
          v-mu-scrollbar
          class="mc-sidebar_body-container">
          <slot />
        </div>
        <div
          v-show="isCollapsed"
          class="mu-box mc-sidebar_body-container">
          <slot name="sidebar-collapsed" />
        </div>
      </div>
      <div
        v-if="footerVisible"
        class="mc-sidebar_footer">
        <a
          v-if="collapseBtnPosition === 'bottom'"
          class="mc-sidebar_collapse-button mc-button-like"
          @mouseover="onCollapseBtnMouseOver"
          @click="toggleCollapse">
          <mu-icon :icon="menuIcon" />
        </a>
        <slot v-if="!isCollapsed" name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, useSlots, provide, nextTick } from 'vue'

  const props = defineProps({
    floatable: Boolean,
    collapsed: Boolean,
    collapsible: Boolean,
    collapseButtonPosition: {
      type: String,
      validator (v) {
        return ['top', 'bottom'].indexOf(v) !== -1
      }
    },
    width: {
      type: String,
      default: '240px'
    },
    accordion: Boolean,
    selectMode: {
      type: String,
      default: 'auto',
      validator (value) {
        return ['auto', 'manual'].indexOf(value) !== -1
      }
    }
  })
  const emits = defineEmits(['floating-change', 'collapsed-change'])

  const slots = useSlots()

  const isCollapsed = ref(props.collapsed)
  const isFloating = ref(props.collapsed)

  const hoverTimer = ref(null)
  const pageMenu = ref(null)
  const expandedGroup = ref(null)
  const activeItem = ref(null)
  const currentHoverItem = ref(null)

  const menuIcon = computed(() => {
    return isCollapsed.value
      ? 'sidebarExpanded'
      : (
        props.floatable
          ? (isFloating.value && !isCollapsed.value ? 'sidebarPin' : 'sidebarCollapsed')
          : 'sidebarCollapsed'
      )
  })

  const collapseBtnPosition = computed(() => {
    return props.collapsible
      ? (
        props.collapseButtonPosition ||
        (slots.footer || !slots.header ? 'bottom' : 'top')
      )
      : ''
  })

  const headerVisible = computed(() => {
    return slots.header ||
      (props.collapsible && props.collapseBtnPosition === 'top')
  })

  const footerVisible = computed(() => {
    return slots.footer ||
      (props.collapsible && props.collapseBtnPosition === 'bottom')
  })

  const sidebarStyle = computed(() => {
    return (isFloating.value || isCollapsed.value)
      ? undefined
      : { width: props.width }
  })

  const contentWidth = computed(() => {
    return isCollapsed.value ? undefined : props.width
  })

  const toggleCollapse = () => {
    if (props.floatable) {
      isFloating.value = !isFloating.value
      emits('floating-change', isFloating.value)

      nextTick(() => {
        isCollapsed.value = isFloating.value
        emits('collapsed-change', isCollapsed.value)
      })
    } else {
      isCollapsed.value = !isCollapsed.value
      emits('collapsed-change', isCollapsed.value)
    }
  }

  const clearHoverTimer = () => {
    if (hoverTimer.value) {
      clearTimeout(hoverTimer.value)
      delete hoverTimer.value
    }
  }

  const delayCollapse = () => {
    hoverTimer.value = setTimeout(() => {
      isCollapsed.value = true
      emits('collapsed-change', isCollapsed.value)
    }, 300)
  }

  const onCollapseBtnMouseOver = () => {
    if (props.floatable && isFloating.value) {
      clearHoverTimer()
      isCollapsed.value = false
      emits('collapsed-change', isCollapsed.value)
    }
  }

  const onSidebarMouseLeave = () => {
    if (props.floatable && isFloating.value && !isCollapsed.value) {
      clearHoverTimer()
      delayCollapse()
    }
  }

  const setActiveItem = (item) => {
    if (activeItem.value !== item) {
      activeItem.value?.unselect()
      activeItem.value = item
    }
  }

  const setCurrentHoverItem = (item) => {
    if (currentHoverItem.value !== item) {
      currentHoverItem.value = item
    }
  }

  const setExpandedGroup = (group) => {
    expandedGroup.value = group
  }

  const getElement = () => {
    return pageMenu.value
  }

  provide('menu', {
    getElement,
    selectMode: props.selectMode,
    activeItem,
    currentHoverItem,
    expandedGroup,
    setExpandedGroup,
    setActiveItem,
    setCurrentHoverItem
  })
</script>
