<template>
  <div
    class="mu-editor mc-page-org-selector"
    :expanded="expanded"
    @click="toggleExpand">
    <mu-icon icon="tree" />
    <mu-input :readonly="true" :value="orgName" />
    <mu-icon icon="dropdown" />
    <mu-dropdown-panel
      v-model="expanded"
      class="mc-page-org-selector_dropdown"
      overflow="none">
      <div class="mc-page-org-selector_bar">
        <mu-button-editor
          v-model="searchText"
          icon="search"
          icon-align="left"
          placeholder="按组织名称查找"
          @esckey="onEscKey" />
      </div>
      <mu-scroll-box class="mc-page-org-selector_org-container">
        <keep-alive>
          <tree-nodes v-if="readonly" />
          <search-nodes v-else :search-text="searchText" />
        </keep-alive>
      </mu-scroll-box>
    </mu-dropdown-panel>
  </div>
</template>

<script>
  import TreeNodes from './tree-nodes.vue'
  import SearchNodes from './search-nodes.vue'

  export default {
    name: 'McPageOrgSelector',
    components: {
      TreeNodes,
      SearchNodes
    },
    inject: ['context'],
    provide () {
      return {
        selector: this
      }
    },
    props: ['orgId'],
    data () {
      return {
        nodes: {},
        readonly: true,
        expanded: false,
        searchText: ''
      }
    },
    computed: {
      orgName () {
        return this.context.orgShortName || this.context.orgName
      }
    },
    watch: {
      expanded (v) {
        if (!v) {
          this.readonly = true
          this.searchText = ''
        }
      },
      searchText (v) {
        if (this.readonly && v) this.readonly = false
        if (!this.readonly && !v) this.readonly = true
      }
    },
    methods: {
      onEscKey () {
        this.readonly = true
      },
      toggleExpand () {
        this.expanded = !this.expanded
      },
      onNodeClick (node) {
        if (node.id.toString() === this.context.orgId.toString()) return
        // 把当前数据保存到本地缓存
        const key = `${this.context.userId}.${this.context.tenantId}.${this.context.applicationId}`
        sessionStorage.setItem(key, JSON.stringify(this.nodes))
        this.$application.redirectModuleUrl({
          orgId: node.id
        })
      }
    }
  }
</script>
