<template>
  <div>
    <div
      class="mu-list-item"
      :title="node.fullName || node.shortName"
      :disabled="!node.isValid"
      :style="{ paddingLeft: indent }"
      @click="onNodeClick">
      <mu-icon
        class="mc-page-org-selector_expand-icon"
        style="text-align: center; cursor: pointer;"
        :icon="expandIcon"
        @click="toggleExpand"
        @click.native.stop />
      <org-icon
        :node-type="node.orgType"
        :ext-type="node.extType"
        :root="node.fullId === node.id.toString()" />
      {{ node.shortName || node.fullName }}
    </div>
    <mc-org-tree-nodes
      v-if="expanded"
      :level="level + 1"
      :parent-id="node.id" />
  </div>
</template>

<script>
  import OrgIcon from './icon.vue'

  const unitIndent = 16

  export default {
    name: 'McOrgTreeNode',
    components: {
      OrgIcon
    },
    inject: ['context', 'selector'],
    props: {
      node: Object,
      level: {
        type: Number,
        default: 0
      },
      idx: Number
    },
    data () {
      return {
        expanded: (!this.level && !this.node.isLeaf && !this.idx) || (
          this.selector.nodes &&
          this.selector.nodes.indexes &&
          this.selector.nodes.indexes[this.node.id] &&
          this.selector.nodes.indexes[this.node.id].childNodes &&
          this.selector.nodes.indexes[this.node.id].childNodes.length
        )
      }
    },
    computed: {
      indent () {
        return unitIndent * (this.level + 1) + 'px'
      },
      expandIcon () {
        return this.node.isLeaf
          ? undefined
          : this.expanded ? 'key-down' : 'key-right'
      }
    },
    mounted () {
      if (this.node.id === this.context.orgId) {
        this.$nextTick(() => {
          this.$el.scrollIntoView()
        })
      }
    },
    methods: {
      toggleExpand () {
        if (!this.node.isLeaf) this.expanded = !this.expanded
      },
      onNodeClick () {
        if (this.node.isValid) this.selector.onNodeClick(this.node)
      }
    }
  }
</script>
