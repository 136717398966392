<!-- 发送反馈 -->
<template>
  <mu-dialog
    :visible="dialogVisible"
    :title="dlgTitle"
    mask-action="none"
    :buttons="buttons"
    class="mc-dialog-send-feedback"
    @buttonclick="onButtonClick"
    @change="onHide">
    <div v-mu-scrollbar class="mu-box inner-panel">
      <mu-form>
        <label>当前情况所在页面</label>
        <mu-form-field flex="1">
          <mu-editor v-model="currentMenu" readonly />
        </mu-form-field>
        <label>描述当前的情况</label>
        <mu-form-field>
          <textarea
            v-model="currentFeedbackInfo.content"
            class="mu-input"
            placeholder="输入内容描述当前的情况" />
        </mu-form-field>
        <label>附件</label>

        <mu-h-box class="mu-form-row images-container">
          <div
            v-if="props.showScreenshot"
            id="screenshot"
            class="image-item"
            @click="showOriginalImage">
            <div class="checkbox-container include-screen-shot" @click="onCheck">
              <input id="includeScreenshot" v-model="includeScreenshot" type="checkbox">
              <label for="includeScreenshot">包含此屏幕截图</label>
            </div>
          </div>
          <div
            v-for="(file, index) in currentFeedbackInfo.attachments"
            :key="file.key"
            class="image-item"
            :style="{ backgroundImage: `url(${file.url})` }"
            @click="showOriginalImage">
            <mu-button
              button-style="text"
              button-type="danger"
              class="delete-image-btn"
              @click="deleteImage(file,index)">
              ×
            </mu-button>
          </div>
          <div
            class="image-item add-image">
            <file-uploader
              :allowed-extensions="allowedExtensions"
              :module-name="moduleName"
              :message-box="{ alert, error, warn, notify }"
              :on-upload-success="onUploadSuccess" />
          </div>
        </mu-h-box>
        <div class="checkbox-container">
          <input
            id="allowContact"
            v-model="allowContact"
            type="checkbox"
            @change="editAllowContact">
          <label for="allowContact" margin-left="1x">你可以就此反馈与我联系</label>
        </div>
        <mu-form-field size="45%">
          <mu-editor v-model="currentFeedbackInfo.userPhone" placeholder="输入手机号码" />
        </mu-form-field>
        <label class="form-email-label"> 或 </label>
        <mu-form-field size="45%">
          <mu-editor v-model="currentFeedbackInfo.userEmail" placeholder="输入电子邮箱地址" />
        </mu-form-field>
      </mu-form>
    </div>
    <dialog-original-image ref="dlgOriginalImage" />
  </mu-dialog>
</template>

<script setup>
  import { ref, shallowRef, watch, inject, computed } from 'vue'
  import { toPng } from 'html-to-image'
  import { alert, error, warn, notify } from 'mussel'
  import FileUploader from './file-uploader.vue'
  import DialogOriginalImage from './dialog-original-image.vue'
  import { getUrl } from '@utils/oss-converter'
  import { uploadFile, getCurrentDate } from '@utils/file-service'
  import uniqueId from '@utils/unique-id'

  const props = defineProps({
    dlgTitle: {
      type: String,
      default: '发送反馈'
    },
    feedbackInfo: {
      type: Object,
      default: null
    },
    showScreenshot: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits(['save'])

  const product = 'platform'
  const moduleName = 'feedback'

  const context = inject('context')

  const dialogVisible = shallowRef(false)
  const dlgOriginalImage = shallowRef(false)

  const buttons = [
    { caption: '取消', key: 'close', 'button-style': 'text' },
    { caption: '发送', buttonType: 'primary', key: 'ok' }
  ]

  const allowedExtensions = ['jpg', 'jpeg', 'png']

  const currentFeedbackInfo = ref({})
  const screenshot = shallowRef({ key: '', url: '' })
  const includeScreenshot = shallowRef(false)
  const allowContact = shallowRef(true)
  const currentMenu = computed(() => {
    return currentFeedbackInfo.value.menuName || currentFeedbackInfo.value.moduleName
  })

  watch(() => props.feedbackInfo, (v) => {
    currentFeedbackInfo.value = Object.assign(
      {
        moduleName: '',
        content: '',
        attachments: [],
        userPhone: '',
        userEmail: '',
        menuName: context.menuName
      },
      props.feedbackInfo)
  })

  function show () {
    dialogVisible.value = true
    init()
    if (props.showScreenshot) {
      takeScreenshot()
    }
  }

  function onHide (e) {
    if (!e) dialogVisible.value = false
  }
  function init () {
    includeScreenshot.value = false
    currentFeedbackInfo.value = Object.assign(
      {
        moduleName: '',
        content: '',
        attachments: [],
        userPhone: '',
        userEmail: ''
      },
      props.feedbackInfo)
  }

  function takeScreenshot () {
    const element = document.getElementsByClassName('mc-page')[0]
    const options = {
      cacheBust: true // 添加时间戳以防止缓存
      // width: 800, // 设置捕获图像的宽度
      // height: 600 // 设置捕获图像的高度
    }
    toPng(element, options).then(dataUrl => {
      // 将 html 转换为图片
      screenshot.value.url = dataUrl
      screenshot.value.key = `屏幕截图-${new Date().getTime()}`
      document.getElementById('screenshot').style.backgroundImage = `url(${dataUrl})`
    })
  }

  function onCheck () {
    event.stopPropagation()
  }

  function deleteImage (image, index) {
    currentFeedbackInfo.value.attachments.splice(index, 1)
    event.stopPropagation()
  }

  function showOriginalImage (element) {
    dlgOriginalImage.value.show(element)
  }

  function editAllowContact () {
    if (!allowContact.value) {
      currentFeedbackInfo.value.userPhone = ''
      currentFeedbackInfo.value.userEmail = ''
    }
  }

  async function onButtonClick (btn) {
    if (btn.key === 'ok') {
      const validated = validate()
      if (!validated) return
      if (includeScreenshot.value) {
        await saveScreenshot(screenshot.value)
        currentFeedbackInfo.value.attachments.unshift(screenshot.value)
      }

      console.log(currentFeedbackInfo.value)
      emit('save', currentFeedbackInfo.value)
    }
    onHide(false)
  }

  function validate () {
    const { content, attachments, userPhone, userEmail } = currentFeedbackInfo.value
    if (!content && !attachments.length) {
      alert('请输入有效的问题描述或上传附件！')
      return false
    }
    if (userPhone && !checkPhoneNumber(userPhone)) {
      return false
    }
    if (userEmail && !checkEmail(userEmail)) {
      return false
    }
    return true
  }

  function checkPhoneNumber (phone) {
    if (!/^1[3-9]\d{9}$/.test(phone)) {
      alert('请输入有效的手机号码！')
      return false
    }

    return true
  }

  function checkEmail (email) {
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
      alert('请输入有效的电子邮箱地址！')
      return false
    }
    return true
  }

  async function onUploadSuccess (fileData, file) {
    const fileKey = fileData.savedKey
    const { url } = await getUrl(fileKey, product, true)
    currentFeedbackInfo.value.attachments.push({
      key: fileKey, url, name: file.name, size: file.size, type: file.type
    })
  }

  async function saveScreenshot (screen) {
    const userId = context.userId
    const fileId = uniqueId()
    const { year, month, date } = getCurrentDate()
    const prefix = `${year}/${month}/${date}`
    const fileKey = userId ? `${prefix}/${userId}/${fileId}.png` : `${prefix}/${fileId}.png`

    const fileObject = dataURLtoFile(screen.url, `${screen.key}.png`)

    const params = {
      product,
      moduleName,
      tenantIgnore: true,
      tempFile: true,
      forPublic: false,
      key: fileKey,
      fileObject,
      fileName: screen.key,
      fileType: 'image/png'
    }

    return new Promise((resolve, reject) => {
      uploadFile({
        ...params,
        success: async (data) => {
          const { savedKey } = data

          screen.type = 'image/png'
          screen.name = screen.key
          screen.key = savedKey
          screen.size = getBase64Size(screen.url)
          resolve(screen)
        },
        error: msg => {
          console.warn('upload fail: ' + msg)
          reject(msg)
        }
      })
    })
  }

  function getBase64Size (base64String) {
    // 移除Base64前缀（如果有）
    if (base64String.startsWith('data:')) {
      base64String = base64String.split(',')[1]
    }

    // 计算Base64字符串的长度
    const stringLength = base64String.length

    // 计算原始字节数
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.75

    return sizeInBytes
  }

  function dataURLtoFile (dataURL, filename) {
    const arr = dataURL.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  defineExpose({ show })
</script>

<style lang="postcss">
  .mc-dialog-send-feedback {
    --mu-border-color: #ebecf0;

    & > .mu-dialog {
      width: 800px;
      height: 80%;
      min-height: 600px;

      & > .mu-dialog-body {
        overflow: hidden;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: 16px;
        align-items: stretch;
      }

      & > .mu-dialog-header,
      & > .mu-dialog-footer {
        padding: 16px;
      }
    }

    .inner-panel {
      display: flex;
      flex: 1 1 auto;

        & > .mu-box {
          width: 100%;
        }

        label {
          font-size: 16px;
        }

        .images-container {
          flex-wrap: wrap;
          padding-top: 8px;
        }

        .image-item {
          width: 220px;
          height: 165px;
          margin: 0 8px 8px 0;

          background-repeat: no-repeat;
          background-size: cover;
        }

        .delete-image-btn {
          float: right;

          width: 32px;
          height: 32px;
          padding: 0;

          font-size: 24px;
        }

        .add-image {
          cursor: pointer;

          font-size: 36px;
          line-height: 165px;
          color: var(--mu-primary-color);
          text-align: center;

          background-color: var(--mu-border-color);
        }

        .checkbox-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          input {
            cursor: pointer;

            width: 16px;
            height: 16px;
            margin-right: 8px;

            border: 1px solid var(--mu-primary-color);
          }
          label {
            cursor: pointer;
          }
        }

        .include-screen-shot {
          padding: 8px;
          text-align: center;
          background-color: rgb(255,255,255,.9);
          box-shadow: 0 1px 1px 0px rgb(0,0,0,.1);
        }

        .mu-form-field {
          padding: 8px 0px;
        }

        .form-email-label {
          width: 10%;
          line-height: 48px;
          text-align: center;
        }

        textarea.mu-input {
          height: 150px;
        }

    }
  }
</style>
