<!-- 发送评论 -->
<template>
  <mu-dialog
    :visible="dialogVisible"
    :title="dlgTitle"
    mask-action="none"
    :buttons="buttons"
    class="dialog-send-comment"
    @buttonclick="onButtonClick"
    @change="onHide">
    <div v-mu-scrollbar class="mu-box inner-panel">
      <mu-form>
        <label>当前情况所在页面</label>
        <mu-form-field flex="1">
          <mu-editor v-model="currentMenu" readonly />
        </mu-form-field>
        <label>描述当前的情况</label>
        <mu-form-field>
          <textarea
            v-model="currentFeedback.content"
            class="mu-input"
            readonly
            placeholder="输入内容描述当前的情况" />
        </mu-form-field>
        <label>附件</label>
        <mu-h-box class="mu-form-row images-container">
          <div
            v-for="file in currentFeedback.attachments"
            :key="file.key"
            class="image-item"
            :style="{ backgroundImage: `url(${file.url})` }"
            @click="showOriginalImage" />
        </mu-h-box>
        <div class="checkbox-container">
          <input
            id="allowContact"
            v-model="allowContact"
            type="checkbox"
            disabled>
          <label for="allowContact" margin-left="1x">你可以就此反馈与我联系</label>
        </div>
        <mu-form-field size="45%">
          <mu-editor v-model="currentFeedback.userPhone" placeholder="输入手机号码" readonly />
        </mu-form-field>
        <label class="form-email-label"> 或 </label>
        <mu-form-field size="45%">
          <mu-editor v-model="currentFeedback.userEmail" placeholder="输入电子邮箱地址" readonly />
        </mu-form-field>
        <div v-if="isBackoffice">
          <label for="">其他信息</label>
          <div class="hidden-info-container">
            <ul>
              <li>
                {{ `租户: ${currentFeedback.tenantName}(${currentFeedback.tenantId})` }}
              </li>
              <li>{{ `组织: ${currentFeedback.orgName}(${currentFeedback.orgId})` }}</li>
              <li>
                {{ `应用: ${currentFeedback.applicationName}(${currentFeedback.applicationId})` }}
              </li>
              <li>
                {{ `模块: ${currentFeedback.moduleName}(${currentFeedback.moduleId})` }}
              </li>
              <li>{{ `创建时间: ${getCreatedAt(currentFeedback.createdAt)}` }}</li>
              <li>
                {{ `创建人: ${currentFeedback.creatorName || ''}(${currentFeedback.creator || ''})` }}
              </li>
              <li>{{ `用户账号: ${currentFeedback.account || ''}` }}</li>
              <li>{{ `手机号码: ${currentFeedback.accountPhone || ''}` }}</li>
            </ul>
          </div>
        </div>

        <mu-v-box class="comment-container">
          <mu-h-box>
            <label>评论</label>
            <mu-icon
              class="comment-expanded-icon"
              :icon="expanded ? 'key-up': 'key-down'"
              @click="showComment" />
          </mu-h-box>
          <div v-show="expanded">
            <div
              v-for="comment in filteredComments"
              :key="comment.createdAt"
              class="comment-item">
              <mu-h-box>
                <mu-icon :icon="comment.userIcon || 'user'" class="comment-icon" />
                <span class="comment-title comment-author">
                  {{ getAuthor(comment) }}
                </span>
                <span class="comment-title comment-time">
                  {{ getCreatedAt(comment.createdAt) }}
                </span>
                <mu-space />
                <mu-button
                  v-if="isOwnComment(comment)"
                  button-style="link"
                  button-type="danger"
                  margin="right"
                  @click="removeComment(comment)">
                  撤回
                </mu-button>
                <mu-button
                  v-if="comment.id"
                  button-style="link"
                  button-type="primary"
                  @click="addComment(comment)">
                  回复
                </mu-button>
              </mu-h-box>
              <mu-form-field>
                <textarea
                  v-model="comment.content"
                  readonly
                  class="mu-input"
                  placeholder="此处输入想说的话" />
              </mu-form-field>
              <div class="comment-survey">
                <span>该回复解决了我的问题？</span>
                <mu-toggle
                  v-model="comment.isSolved"
                  active-label="是"
                  inactive-label="否"
                  @change="editComment(comment)" />
              </div>
              <mu-h-box v-if="comment.refId" class="comment-ref">
                <span class="ref-dec" :title="getRefCommentInfo(comment)">
                  {{ getRefCommentInfo(comment) }}
                </span>
              </mu-h-box>
            </div>
          </div>
        </mu-v-box>
      </mu-form>
    </div>
    <div class="add-comment-container">
      <label>发表评论</label>
      <mu-form-field>
        <textarea
          v-model="newComment.content"
          class="mu-input"
          placeholder="此处输入想说的话，按 Ctrl+Enter 发表"
          @keydown.enter.ctrl.exact.prevent="saveComment" />
      </mu-form-field>
      <mu-h-box v-if="newComment.refId" class="comment-ref">
        <span class="ref-dec" :title="getRefCommentInfo(newComment)">
          {{ getRefCommentInfo(newComment) }}
        </span>
      </mu-h-box>
      <mu-button
        button-type="primary"
        margin="left"
        @click="saveComment">
        发表
      </mu-button>
    </div>
    <dialog-original-image ref="dlgOriginalImage" />
  </mu-dialog>
</template>

<script setup>
  import { ref, shallowRef, computed, watch } from 'vue'
  import { confirm } from 'mussel'
  import dayjs from 'dayjs'
  import DialogOriginalImage from './dialog-original-image.vue'

  const props = defineProps({
    dlgTitle: {
      type: String,
      default: '发表评论'
    },
    feedbackInfo: {
      type: Object,
      default: null
    },
    comments: {
      type: Array,
      default: () => []
    },
    isBackoffice: {
      type: Boolean,
      default: false
    },
    operator: {
      type: Object,
      default: () => {
        return {
          id: 1,
          name: '梦诚客服'
        }
      }
    }

  })

  const emit = defineEmits(['add-comment', 'update-comment'])
  const dialogVisible = shallowRef(false)
  const dlgOriginalImage = shallowRef(false)

  const buttons = [
    { caption: '关闭', key: 'close', 'button-style': 'text' }
  ]

  const roleMap = {
    user: '作者',
    handler: '梦诚客服'
  }

  const currentFeedback = ref({})
  const currentComments = ref([])
  const includeScreenshot = shallowRef(false)
  const allowContact = shallowRef(true)
  const expanded = shallowRef(true)
  const newComment = shallowRef({ role: 'user', refId: null, content: '' })

  const currentMenu = computed(() => {
    return currentFeedback.value.menuName || currentFeedback.value.moduleName
  })

  const filteredComments = computed(() => {
    if (!currentComments.value) return []
    return currentComments.value.filter(item => !item.isRetracted)
  })

  watch(() => props.feedbackInfo, (v) => {
    currentFeedback.value = Object.assign(
      {
        moduleName: '',
        content: '',
        attachments: [],
        userPhone: '',
        userEmail: '',
        comments: []
      },
      {
        ...props.feedbackInfo,
        createdAt: props.feedbackInfo.createdAt ? dayjs(props.feedbackInfo.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''
      })
  })

  watch(() => [...props.comments], (v) => {
    currentComments.value = [...props.comments]
  })

  function show () {
    dialogVisible.value = true
    init()
  }

  function onHide (e) {
    if (!e) dialogVisible.value = false
  }
  function init () {
    includeScreenshot.value = false
    currentFeedback.value = Object.assign(
      {
        moduleName: '',
        content: '',
        attachments: [],
        userPhone: '',
        userEmail: ''
      },
      props.feedbackInfo)
    newComment.value = { role: props.isBackoffice ? 'handler' : 'user', refId: null, content: '' }
  }

  function showOriginalImage (element) {
    dlgOriginalImage.value.show(element)
  }

  function showComment () {
    expanded.value = !expanded.value
  }

  function isOwnComment (comment) {
    return props.isBackoffice
      ? comment.role === 'handler' && props.operator.id === comment.userId
      : comment.role === 'user'
  }

  function getAuthor (comment) {
    if (!props.isBackoffice) {
      return `${roleMap[comment.role]}${comment.role === 'user' ? '（我）' : ''}`
    } else {
      return (comment.role === 'user')
        ? '反馈人'
        : `梦诚：${comment.username || '梦诚客服'}${props.operator.id === comment.userId ? '（我）' : ''}`
    }
  }

  function getRefCommentInfo (comment) {
    const refComment = currentComments.value.find(item => item.id === comment.refId)
    if (!refComment) {
      if (!props.isBackoffice) {
        return `${roleMap[comment.refRole]}${comment.refRole === 'user' ? '（我）' : ''}：评论已被撤回`
      } else {
        return (comment.refRole === 'user')
          ? '反馈人：评论已被撤回'
          : `梦诚：${comment.refUsername || '梦诚客服'}${props.operator.id === comment.refUserId ? '（我）' : ''}：评论已被撤回`
      }
    }
    const author = getAuthor(refComment)
    return `${author}：${refComment.content}`
  }

  function getCreatedAt (createdAt) {
    return createdAt ? formatDate(new Date(createdAt)) : ''
  }

  function formatDate (date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  function addComment (comment) {
    const newItem = {
      role: props.isBackoffice ? 'handler' : 'user',
      refId: comment?.id || null
    }
    newComment.value = Object.assign({ ...newComment.value }, { ...newItem })
  }

  function editComment (comment) {
    const feedbackId = currentFeedback.value.id
    emit('update-comment', {
      feedbackId,
      comment: {
        id: comment.id,
        isSolved: comment.isSolved,
        content: comment.content,
        role: comment.role
      }
    })
  }

  function removeComment (comment) {
    const feedbackId = currentFeedback.value.id
    confirm('确认撤回评论？', async btn => {
      if (btn === 'ok') {
        emit('update-comment', {
          feedbackId,
          comment: {
            id: comment.id,
            isRetracted: true,
            content: comment.content,
            role: comment.role
          }
        })
        const findIndex = currentComments.value.findIndex(item => item.id === comment.id)
        currentComments.value[findIndex] = {
          ...comment,
          isRetracted: true
        }
        currentComments.value = [].concat(currentComments.value)
      }
    })
  }

  async function saveComment () {
    const feedbackId = currentFeedback.value.id
    newComment.value = {
      ...newComment.value,
      userId: props.isBackoffice ? props.operator.id : null,
      username: props.isBackoffice ? props.operator.name : null
    }
    await emit('add-comment', { feedbackId, comment: { ...newComment.value } })

    newComment.value.content = ''
    newComment.value.refId = null

    // 将新添加的评论滚动到可视区域
    // 记录开始时间
    const startTime = new Date().getTime()
    let intervalId = ''
    // 查找元素的循环
    intervalId = setInterval(() => {
      const commentItemCount = document.getElementsByClassName('comment-item').length
      if (commentItemCount === currentComments.value.length) {
        // 元素更新，清除定时器并执行回调
        clearInterval(intervalId)
        const divElement = document.getElementsByClassName('inner-panel')[0]
        divElement.scrollTop = divElement.scrollHeight
      } else if (new Date().getTime() - startTime > 5000) {
        // 超时，清除定时器
        clearInterval(intervalId)
      }
    }, 100) // 每100毫秒检查一次
  }

  function onButtonClick (btn) {
    onHide(false)
  }

  defineExpose({ show })
</script>

<style lang="postcss">
  .dialog-send-comment {
    --mu-border-color: #ebecf0;

    & > .mu-dialog {
      width: 800px;
      height: 90%;
      min-height: 600px;

      & > .mu-dialog-body {
        position: relative;

        overflow: hidden;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: 16px;
        align-items: stretch;

        padding: 0;
      }

      & > .mu-dialog-header,
      & > .mu-dialog-footer {
        padding: 16px;
      }
    }

    .inner-panel {
      display: flex;
      flex: 1 1 auto;
      padding: 16px;

        & > .mu-box {
          width: 100%;
        }

        label {
          font-size: 16px;
        }

        .images-container {
          flex-wrap: wrap;
          padding-top: 8px;
        }

        .image-item {
          width: 220px;
          height: 165px;
          margin: 0 8px 8px 0;

          background-repeat: no-repeat;
          background-size: cover;
        }

        .delete-image-btn {
          float: right;

          width: 32px;
          height: 32px;
          padding: 0;

          font-size: 24px;
        }

        .checkbox-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          input {
            cursor: pointer;

            width: 16px;
            height: 16px;
            margin-right: 8px;

            border: 1px solid var(--mu-primary-color);
          }
          label {
            cursor: pointer;
          }
        }

        .mu-form-field {
          padding: 8px 0px 4px 0px;
        }

        .form-email-label {
          width: 10%;
          line-height: 48px;
          text-align: center;
        }

        .comment-container {
          width: 100%;
        }

        .comment-expanded-icon {
          cursor: pointer;
          margin-top: 0px;
          margin-left: 8px;
          font-size: 20px;
        }

        .comment-expanded-icon:hover {
          color: var(--mu-primary-color);
        }

        .comment-item {
          position: relative;
          margin-top: 8px;
        }

        .comment-item .mu-form-field {
          padding-top: 0px;
        }

        .comment-item .comment-survey {
          position: absolute;
          z-index: 2;
          top: 78px;
          right: 4px;

          height: 29px;
          padding: 4px 8px;

          line-height: 22px;

          background-color: rgba(255,255,255,.8);
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);

          .mu-toggle {
            height: 20px;
          }
        }

        .comment-item .comment-icon {
          margin-top: 8px;
        }

        .comment-item .comment-title {
          margin: 0 4px;
          line-height: 32px;
        }

        .comment-item .comment-author {
          color: var(--mu-primary-color);
        }

        .comment-item .comment-time {
          color: grey;
        }

        .mu-input[readonly] {
          color: #333;
          background-color: #FFEB3B12;
          border-color: #d9d9d9;
          box-shadow: none;
        }

        .hidden-info-container {
          width: 100%;
          height: 100px;
          margin: 8px 0;

          background-color: #FFEB3B12;
          border: 1px solid var(--mu-border-color);
        }

        .hidden-info-container ul {
          margin: 0;
          padding: 0 8px;
        }

        .hidden-info-container ul > li {
          float: left;
          width: 50%;
          line-height: 24px;
          list-style-type: none;
        }
    }

    .add-comment-container {
      padding: 8px 16px;
      background-color: #fafafa;
      box-shadow: 0px -2px 4px rgba(0,0,0,.1);

      .comment-ref {
        margin-bottom: 8px;
        margin-left: 8px;
      }

      label {
        padding-left: 8px;
        font-size: 16px;
      }

      textarea.mu-input {
        height: 150px;
      }
    }
    .comment-ref {
      width: 752px;
      height: 30px;
      padding: 0 10px;

      line-height: 30px;
      color: grey;

      background-color: #fafafa;
      border: 1px solid var(--mu-border-color);
      border-radius: 4px;
    }
    .comment-ref .ref-dec {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
