<template>
  <mc-sidebar-menu
    class="mc-page-menu"
    floatable
    collapsible
    collapse-button-position="top"
    :width="width"
    :collapsed="collapsed"
    @sizechange="onSizeChange"
    @floating-change="onFloatingChange"
    @collapsed-change="onCollapsedChange">
    <template #header>
      <div
        class="mc-sidebar_header-content">
        产品菜单
      </div>
    </template>
    <div class="mu-box mc-sidebar_menu-wrapper" position="absolute fit">
      <template v-for="item in rootMenus">
        <mc-menu-group
          v-if="item.menus"
          :key="`menu-group-${item.id}`"
          :icon="item.icon || 'menu'"
          :title="item.aliasName">
          <template v-for="childItem in item.menus">
            <mc-menu-group
              v-if="childItem.menus"
              :key="`child-group-${childItem.id}`"
              :icon="childItem.icon || undefined"
              :title="childItem.aliasName">
              <template v-for="grandchildItem in childItem.menus">
                <mc-menu-item
                  v-if="!grandchildItem.menus"
                  :key="grandchildItem.id"
                  :item="grandchildItem" />
              </template>
            </mc-menu-group>
            <mc-menu-item v-else :key="`child-item-${childItem.id}`" :item="childItem" />
          </template>
        </mc-menu-group>
        <mc-menu-item v-else :key="`menu-item-${item.id}`" :item="item" />
      </template>
    </div>
    <template v-if="collapsedMenu" #sidebar-collapsed>
      <div
        v-if="showCollapsedButton"
        ref="scrollUpButton"
        class="mc-sidebar_collapsed_scroll-button mc-sidebar_scroll-up-button"
        @click.stop="scrollUp">
        <mu-icon
          icon="menuGroupCollapsed"
          :disabled="isTopDisabled || null" />
      </div>
      <div
        ref="collapsedSidebar"
        v-mu-scrollbar
        class="mu-box mc-sidebar_menu-wrapper mc-sidebar_collapsed">
        <mc-menu-icon-group
          v-for="item in rootMenus" :key="item.id"
          :item="item" />
      </div>
      <div
        v-if="showCollapsedButton"
        ref="scrollDownButton"
        class="mc-sidebar_collapsed_scroll-button mc-sidebar_scroll-down-button"
        @click.stop="scrollDown">
        <mu-icon
          icon="menuGroupExpanded"
          :disabled="isBottomDisabled || null" />
      </div>
    </template>
  </mc-sidebar-menu>
</template>

<script>
  import McMenuItem from './menu-item.vue'
  import McMenuGroup from './menu-group.vue'
  import McSidebarMenu from './sidebar-menu.vue'
  import McMenuIconGroup from './menu-icon-group.vue'

  import { getLocalState, setLocalState } from '../../utils/local-store'

  export default {
    name: 'McPageMainMenu',
    components: {
      McMenuItem,
      McMenuGroup,
      McSidebarMenu,
      McMenuIconGroup
    },
    props: {
      menus: Array,
      width: String,
      collapsedMenu: {
        type: Boolean,
        default: true
      }
    },
    data () {
      const collapsed = parseInt(getLocalState('MCTECH_CLOUD_MENU_FLOATING'))

      return {
        rootMenus: [],
        showCollapsedButton: true,
        collapsed: isNaN(collapsed)
          ? document.documentElement.clientWidth < 1500
          : !!collapsed,
        sizeChangeTimer: null,
        currentPosition: 0,
        scrollStep: 49,
        resizeTrigger: 0,
        isTopDisabled: true,
        isBottomDisabled: false
      }
    },
    // computed: {
    //   scrollTop () {
    //     return this.currentPosition <= 0
    //   },
    //   scrollBottom () {
    //     const container = this.$refs.collapsedSidebar
    //     const resizeTrigger = this.resizeTrigger
    //     if (!container) return false
    //     return this.currentPosition >= container.scrollHeight - container.clientHeight
    //   }
    // },
    mounted () {
      this.loadData()
    },
    unmounted () {
      this.clearSizeChangeTimer()
      const menuContainer = this.$refs.collapsedSidebar
      if (menuContainer) {
        menuContainer.removeEventListener('scroll', this.updateScrollButtonState)
      }
    },
    methods: {
      onFloatingChange (value) {
        setLocalState('MCTECH_CLOUD_MENU_FLOATING', value ? 1 : 0)
        if (!value) {
          this.$nextTick(() => this.sidebarScrollButton())
        }
      },

      async getMenus () {
        const lastRefreshTime = getLocalState('MCTECH_CLOUD_MENUS_REFRESH_TIME')
        const difference = Math.abs(
          new Date().getTime() - (new Date(lastRefreshTime || new Date())).getTime()
        )

        const forceRefresh =
          window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD &&
          (!lastRefreshTime || (difference / 1000 > 30))

        const data = await this.$http.get({
          url: '/services/menus',
          params: {
            forceRefresh
          }
        })
        setLocalState('MCTECH_CLOUD_MENUS_REFRESH_TIME', new Date())
        return data
      },

      async loadData () {
        const data = this.menus || await this.getMenus()
        if (!data) return

        const indexes = {}
        const menus = []

        data
          .sort((a, b) => a.sortNum - b.sortNum)
          .forEach(item => {
            const { id, parentId } = item

            const existItem = indexes[id]
            if (existItem) item = Object.assign(existItem, item)
            else indexes[id] = item

            if (parentId.toString() === '-1') {
              menus.push(item)
            } else {
              const parent = indexes[parentId] || {}

              if (isNaN(parent.id)) {
                indexes[parentId] = Object.assign(
                  parent,
                  { id: parentId, menus: [] }
                )
              } else if (!parent.menus) {
                parent.menus = []
              }

              parent.menus.push(item)
            }
          })
        this.rootMenus = menus
        this.$application.setMenus(data)
        if (this.collapsed) {
          this.$nextTick(() => {
            const menuContainer = this.$refs.collapsedSidebar
            if (menuContainer) {
              menuContainer.scrollTop = 0
            }
            this.sidebarScrollButton()
          })
        }
      },

      onSizeChange () {
        this.clearSizeChangeTimer()
        this.sizeChangeTimer = setTimeout(() => {
          if (this.collapsed) {
            this.$nextTick(() => this.sidebarScrollButton())
          }
        })
      },

      onCollapsedChange (value) {
        if (value) {
          this.$nextTick(() => {
            const menuContainer = this.$refs.collapsedSidebar
            if (menuContainer) {
              menuContainer.scrollTop = 0
            }
            this.sidebarScrollButton()
          })
        }
      },

      sidebarScrollButton () {
        const el = this.$refs.collapsedSidebar

        if (!el) return

        const clientHeight = el.clientHeight
        const scrollHeight = el.scrollHeight
        this.showCollapsedButton = scrollHeight > clientHeight
        this.updateScrollButtonState()

        el.addEventListener('scroll', this.updateScrollButtonState)
      },

      clearSizeChangeTimer () {
        if (this.sizeChangeTimer) {
          clearTimeout(this.sizeChangeTimer)
          this.sizeChangeTimer = null
        }
      },

      updateScrollButtonState () {
        const menuContainer = this.$refs.collapsedSidebar
        if (!menuContainer) return

        this.currentPosition = menuContainer.scrollTop
        this.isTopDisabled = this.currentPosition <= 0
        this.isBottomDisabled =
          this.currentPosition >= menuContainer.scrollHeight - menuContainer.clientHeight
      },

      scrollUp () {
        const menuContainer = this.$refs.collapsedSidebar

        if (this.currentPosition > 0) {
          this.currentPosition -= this.scrollStep
          menuContainer.scrollTop = this.currentPosition
        }
        this.updateScrollButtonState()
      },

      scrollDown () {
        const menuContainer = this.$refs.collapsedSidebar

        this.currentPosition += this.scrollStep

        if (this.currentPosition > menuContainer.scrollHeight - menuContainer.clientHeight) {
          this.currentPosition = menuContainer.scrollHeight - menuContainer.clientHeight
        }
        menuContainer.scrollTop = this.currentPosition
        this.updateScrollButtonState()
      }
    }
  }
</script>
