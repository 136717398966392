<template>
  <div
    class="mc-page-header_button"
    @click="onButtonClick">
    <mu-icon icon="cloudDownload" />
  </div>
</template>

<script>
  export default {
    name: 'McPageDownloadMenu',
    methods: {
      onButtonClick () {
        this.$application.redirectUrl({
          url: '/cb/product-download.html',
          newTab: true
        })
      }
    }
  }
</script>
