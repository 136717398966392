<template>
  <mu-dialog
    :visible="dialogVisible"
    title=" "
    mask-action="none"
    class="dialog-original-image"
    @change="onHide">
    <div class="mu-box inner-pannel">
      <img id="originalImage">
    </div>
  </mu-dialog>
</template>

<script setup>
  import { shallowRef } from 'vue'
  const dialogVisible = shallowRef(false)

  function show (element) {
    dialogVisible.value = true
    const url = element.currentTarget.style.backgroundImage
    if (!url) return

    // 记录开始时间
    const startTime = new Date().getTime()
    let intervalId = ''

    // 查找元素的循环
    intervalId = setInterval(() => {
      const originalImage = document.getElementById('originalImage')
      if (originalImage) {
        // 找到元素，清除定时器并执行回调
        clearInterval(intervalId)
        originalImage.src = url.match(/url\("([^)]+)"\)/)[1]
        // originalImage.src = url
      } else if (new Date().getTime() - startTime > 2000) {
        // 超时，清除定时器
        clearInterval(intervalId)
      }
    }, 100) // 每100毫秒检查一次
  }

  function onHide (e) {
    if (!e) dialogVisible.value = false
  }

  defineExpose({ show })

</script>

<style>
  .dialog-original-image {
    & > .mu-dialog {
      width: 90%;
      height: 90%;
      min-height: 600px;
      min-width: 800px;
      & > .mu-dialog-body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
        flex: 1 1 auto;
        padding: 24px;
        overflow: auto;
      }
    }

    .inner-pannel {
        & > img {
        width: 100%;
        }
    }
  }
</style>
