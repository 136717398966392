<template>
  <div class="mc-page-breadcrumb">
    <div class="mc-page-breadcrumb_item" @click="go(0)">
      <mu-icon
        v-if="homeCrumb.icon"
        :icon="homeCrumb.icon" />
      <div class="mc-page-breadcrumb_item-name">
        {{ homeCrumb.name }}
      </div>
    </div>
    <div
      v-for="(el, idx) in crumbs"
      :key="idx"
      :size="el.name ? 'auto' : undefined"
      class="mc-page-breadcrumb_item">
      <mu-icon
        v-if="idx"
        class="mc-page-breadcrumb_divider"
        icon="key-right" />
      <span
        v-else
        class="mc-page-breadcrumb_divider" />
      <div
        v-if="el.name"
        class="mc-page-breadcrumb_item-name mu-text-ellipsis"
        :title="el.title || el.name"
        @click="go(el.index)">
        {{ el.title || el.name }}
      </div>
      <mu-icon
        v-else
        icon="ellipsis" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'McPageBreadCrumb',
    inject: ['application', 'context'],
    computed: {
      crumbs () {
        const breadcrumbs = this.application.breadcrumbs
        const items = []

        for (let i = 1, len = breadcrumbs.length; i < len; i++) {
          if (len > 8 && i > 2 && i < len - 5) {
            if (i === 3) items.push({})
          } else {
            items.push(breadcrumbs[i])
          }
        }

        return items
      },
      homeCrumb () {
        const { crumbHomeIcon, breadcrumbs = [] } = this.application

        return {
          icon:
            crumbHomeIcon || (
              this.crumbs.length
                ? 'key-left'
                : undefined
            ),
          name:
            breadcrumbs[0].title || (
              this.crumbs.length
                ? '返回'
                : this.context.menuName || '首页'
            )
        }
      }
    },
    methods: {
      go (index) {
        if (index !== this.application.breadcrumbs.length - 1) {
          this.application.routerGoIndex(index)
        }
      }
    }
  }
</script>
