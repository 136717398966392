<template>
  <div v-if="initialized" class="mc-page">
    <page-header v-if="!hideFrame" />
    <div class="mc-page-body">
      <main-menu v-if="menuVisible" :menus="menus" />
      <div
        class="mc-page-client"
        :flex-direction="$slots.columns ? 'column' : ($slots.rows && 'row')">
        <slot v-if="$slots.columns" name="columns" />
        <slot v-else-if="$slots.rows" name="rows" />
        <template v-else>
          <breadcrumb v-if="breadcrumbVisible" />
          <div class="mc-page-slot-wrapper">
            <slot />
          </div>
        </template>
      </div>
    </div>
    <mu-dialog
      v-model="mxzIntroDialogVisible"
      class="mxz-intro-dialog"
      title="欢迎试用梦小智"
      :footer="false">
      <iframe
        src="/friend-mxz.html"
        width="100%"
        height="100%"
        style="border: 0;" />
    </mu-dialog>
  </div>
</template>

<script>
  import Vue from 'vue'

  import { setTheme } from 'mussel'

  import PageHeader from './header/index.vue'
  import MainMenu from './menu-new/index.vue'
  import Breadcrumb from './breadcrumb.vue'

  import {
    loadPortalContext,
    loadModuleContext,
    loadApplications,
    loadPortalConfig,
    loadMessages,
    loadTodoList
  } from './actions'

  import {
    http,
    query,
    redirectUrl,
    redirectModuleUrl,
    loadingAnimation,
    blockingAnimation,
    showSuccessMessage,
    showBizErrorMessage,
    showFatalErrorMessage,
    injectGrowingIOScript
  } from '../utils'

  import { getLocalState, setLocalState } from '../utils/local-store'

  export default {
    name: 'McPage',
    components: {
      PageHeader,
      MainMenu,
      Breadcrumb
    },
    provide () {
      return {
        application: this,
        context: this.context
      }
    },
    props: {
      theme: null,
      frameMode: {
        type: String,
        default: 'module',
        validator (value) {
          return ['module', 'portal', 'standalone'].indexOf(value) !== -1
        }
      },
      crumbHomeTitle: String,
      crumbHomeIcon: String,
      crumbsVisible: {
        type: String,
        default: 'auto',
        validator (value) {
          return ['none', 'auto', 'always'].indexOf(value) !== -1
        }
      },
      moduleId: String,
      moduleName: String,
      title: String,
      logo: String,
      logoIcon: String,
      logoutText: String,
      logoutClose: Boolean,
      logoutService: String,
      menus: Array
    },
    data () {
      return {
        initialized: false,
        context: {
          batchId: '',
          userId: null,
          userName: '',
          tenantId: null,
          tenantCode: null,
          tenantLogo: '',
          tenantName: '',
          orgId: null,
          orgName: '',
          orgShortName: '',
          orgType: '',
          orgExtType: '',
          menuName: '',
          moduleId: '',
          moduleName: '',
          portalName: '',
          permissions: [],
          applicationId: '',
          applicationLogo: '',
          applicationTitle: '',
          licenseMode: null,
          extraMenus: [],
          menus: this.menus || []
        },
        breadcrumbs: [
          {
            name: '/',
            title: this.crumbHomeTitle
          }
        ],
        hideFrame: false,
        mxzIntroDialogVisible: false
      }
    },
    computed: {
      menuVisible () {
        return !this.hideFrame && (this.frameMode === 'module' || this.menus)
      },
      breadcrumbVisible () {
        return (
          this.crumbsVisible === 'always' ||
          (this.crumbsVisible !== 'none' && this.breadcrumbs?.length > 1)
        )
      }
    },
    watch: {
      initialized (value) {
        if (value) {
          Vue.nextTick(() => {
            if (
              (getLocalState('MCTECH_MXZ_LOGIN_NOTIFIED') === '0') &&
              (new Date() < new Date('2023-10-01'))
            ) {
              setLocalState('MCTECH_MXZ_LOGIN_NOTIFIED', 1)
              this.mxzIntroDialogVisible = true
            }
          })
        }
      }
    },
    beforeCreate () {
      this.loadingAnimation = loadingAnimation
      this.blockingAnimation = blockingAnimation

      Vue.prototype.$application = this
    },
    created () {
      Vue.prototype.$context = this.context

      if (this.theme) {
        setTheme(this.theme)
      }

      if (this.$store) {
        this.$store.$http = this.$http
        this.$store.$context = this.context
      }

      this.hideFrame = query.frame === 'n' || this.frameMode === 'standalone'
      this.initialized = this.frameMode === 'standalone'
    },
    mounted () {
      switch (this.frameMode) {
        case 'portal':
          this.loadPortalContext()
          break
        case 'module':
          this.loadModuleContext()
          break
      }
    },
    methods: {
      setMenus (menus = []) {
        this.context.menus = menus

        const menu = menus.find(
          v => v.moduleId === this.context.moduleId
        )

        if (menu) {
          document.title = menu.aliasName
          this.context.menuName = menu.aliasName
        }
      },
      setContext (data) {
        const {
          applicationId,
          moduleId,
          batchId,
          orgId,
          licenseMode,
          growingIOProjectId
        } = data

        if (this.moduleName) {
          data.moduleName = this.moduleName
        }

        if (batchId) {
          window.webframeContext = {
            batchId,
            licenseMode,
            growingIOProjectId
          }
        }

        if (growingIOProjectId) {
          injectGrowingIOScript(data)
        }

        http.setContextHeaders({
          applicationId,
          moduleId,
          batchId,
          orgId
        })

        Object.assign(this.context, data, {
          isAdmin: data.userId === data.tenantId
        })
      },
      showPromptView (code, desc) {
        this.showBizErrorMessage(desc || '页面初始化数据加载失败！')
      },
      routerPush ({ name, title, params, skipRoute }) {
        const len = this.breadcrumbs.length

        this.breadcrumbs.push({
          name,
          params,
          index: len,
          title: title || name
        })

        if (!skipRoute) {
          this.$router?.replace({ name, params })
        }
      },
      routerReplace ({ name, title, params, skipRoute }) {
        const len = this.breadcrumbs.length

        this.breadcrumbs[len ? len - 1 : 0] = {
          name,
          params,
          index: len - 1,
          title: title || name
        }

        if (!skipRoute) {
          this.$router?.replace({ name, params })
        }
      },
      updateBreadcrumbsTitle (title, index) {
        const updateIndex = index ?? this.breadcrumbs.length - 1

        this.breadcrumbs[updateIndex].title = title
      },
      routerGoIndex (index, skipRoute) {
        const { name, params } = this.breadcrumbs[index]
        const len = this.breadcrumbs.length

        this.breadcrumbs.splice(index + 1, len - index - 1)

        if (!skipRoute) {
          this.$router?.replace({ name, params })
        }
      },
      validatePermissions () {
        const permissions = [...arguments]

        return this.context.permissions.some(
          v => permissions.some(t => t === v)
        )
      },

      loadMessages,
      loadTodoList,
      loadApplications,
      loadPortalConfig,
      loadPortalContext,
      loadModuleContext,
      redirectUrl,
      redirectModuleUrl,
      showSuccessMessage,
      showBizErrorMessage,
      showFatalErrorMessage
    }
  }
</script>

<style>
  .mxz-intro-dialog > .mu-dialog {
    width: 85%;
    max-width: 800px;
    height: 85%;
    max-height: 1200px;

    & > .mu-dialog-body {
      flex: 1 1 0;
    }
  }
</style>
