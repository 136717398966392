import sidebarPin from './sidebar-pin.svg'
import sidebarCollapsed from './sidebar-collapsed.svg'
import sidebarExpanded from './sidebar-expanded.svg'
import menuGroupCollapsed from './menu-group-collapsed.svg'
import menuGroupExpanded from './menu-group-expanded.svg'

export default {
  sidebarPin,
  sidebarCollapsed,
  sidebarExpanded,
  menuGroupCollapsed,
  menuGroupExpanded
}
