<template>
  <div
    class="mu-dropdown mc-page-header_button"
    :expanded="popupParams.visible"
    @click="onClick">
    <mu-icon icon="applications" />
    <mu-dropdown-panel
      v-show="!disabled && popupParams.visible"
      class="mc-page-apps-menu_dropdown"
      v-bind="popupParams"
      overflow="none"
      @show="loadData"
      @change="setPopupVisible"
      @click.native="hidePopup">
      <div
        v-mu-scrollbar
        class="mu-list-group"
        @click.stop>
        <div style="position: relative; height: 40px;">
          <mu-icon
            icon="refresh"
            style=" cursor: pointer;

            position:absolute;
            z-index: 9999; top: 0;right: 10px;

            line-height: 40px;"
            @click="loadData(true)" />
          <mu-button-editor
            icon="search"
            icon-align="left"
            :clearable="false"
            placeholder="平台应用列表 | 搜索"
            style="height: 40px;"
            @input="search"
            @clear="search()" />
        </div>
        <div v-for="group in groups" :key="group.name">
          <div
            v-if="group.name !== '_'"
            class="mu-list-group-header">
            <mu-icon />
            {{ group.name }}
          </div>
          <mu-list-item
            v-for="item in group.items"
            :key="item.id"
            :icon="item.icon || 'application'"
            :label="item.name"
            @click="onItemClick(item)" />
        </div>
      </div>
    </mu-dropdown-panel>
  </div>
</template>

<script>
  import { Dropdown } from 'mussel'

  export default {
    name: 'McPageAppsMenu',
    extends: Dropdown,
    inject: ['context'],
    data () {
      return {
        items: [],
        groups: [],
        appsMap: {},
        portalConfig: 0,
        isDingTalk: window.navigator.userAgent?.indexOf('DingTalk') !== -1
      }
    },
    methods: {
      async loadData (force) {
        if (force !== true && this.items.length) return

        const apps = await this.$application.loadApplications(force === true)

        if (!apps) return

        this.items = apps
        this.appsMap = apps.reduce((appsObject, app) => {
          appsObject = {
            ...appsObject,
            [app.id || app.name]: app
          }
          return appsObject
        }, {})

        const config = await this.$application.loadPortalConfig()

        if (config) {
          this.portalConfig = { ...config }
          this.filterPortalApps()
        } else {
          this.filter()
        }
      },
      filter (key = '') {
        const defaultGroup = {
          name: '_',
          items: []
        }
        const indexes = {
          _: defaultGroup
        }
        this.groups = [defaultGroup]
        this.items
          .filter(item => {
            return !key || item.name.indexOf(key) >= 0
          })
          .forEach(item => {
            const { appGroup = '_' } = item
            let group = indexes[appGroup]
            if (!group) {
              group = {
                name: appGroup,
                items: []
              }
              indexes[appGroup] = group
              this.groups.push(group)
            }
            group.items.push(item)
          })
      },
      filterPortalApps (key = '') {
        const applicationCenterGroups =
          this.getNewGroups('applicationCenter', key)

        const dataCenterGroups =
          this.getNewGroups('dataCenter', key)
        const otherGroup = this.getOtherGroup(applicationCenterGroups, dataCenterGroups, key)
        this.groups = applicationCenterGroups.concat(dataCenterGroups).concat(otherGroup)
      },

      getNewGroups (centerName, key = '') {
        const newGroups = this.portalConfig[centerName].groups
          .map(group => {
            const newApps = group.applications
              .filter(app => this.appsMap[app.id || app.name] &&
                (!key || app.name.indexOf(key) >= 0))
            return {
              ...group,
              name: centerName === 'dataCenter' ? '数据中心' : group.name,
              items: newApps
            }
          })
          .filter(group => group.items.length)
        return newGroups
      },

      getOtherGroup (applicationCenterGroups, dataCenterGroups, key = '') {
        const portalApps = applicationCenterGroups
          .concat(dataCenterGroups)
          .reduce((prev, curr) => {
            if (curr.items.length) {
              prev = prev.concat(curr.items)
            }
            return prev
          }, [])
        const result = this.items
          .filter(item => !portalApps.some(el => el.id === item.id) &&
            (!key || item.name.indexOf(key) >= 0))
          .map(item => {
            return {
              ...item
            }
          })
        if (result && result.length) {
          return [{
            name: '其他',
            items: result || []
          }]
        } else {
          return []
        }
      },

      search (key = '') {
        if (!this.items.length) return
        if (this.$searchTimer) clearTimeout(this.$searchTimer)

        this.$searchTimer = setTimeout(() => {
          if (this.portalConfig === 0) {
            this.filter(key.trim())
          } else {
            this.filterPortalApps(key.trim())
          }
        }, 500)
      },
      onItemClick (item) {
        const orgId = this.context.orgId
        const url = `/applications/${item.id}${orgId ? `?orgId=${orgId}` : ''}`

        if (this.isDingTalk) window.location.href = url
        else window.open(url)
      }
    }
  }
</script>
