<template>
  <div v-if="nodes.length" class="mc-page-org-selector_search-list">
    <div
      v-for="node in nodes"
      :key="node.id"
      class="mu-list-item"
      :title="getFullName(node)"
      @click="onNodeClick(node)">
      <div class="mu-text-ellipsis">
        <org-icon
          :node-type="node.orgType"
          :ext-type="node.extType"
          :root="node.fullId === node.id.toString()" />
        {{ getName(node) }}
      </div>
      <div class="mu-text-subtitle mu-text-ellipsis">
        {{ getFullName(node) }}
      </div>
    </div>
  </div>
  <div v-else class="mc-page-org-selector_search-list-empty">
    没有符合条件的组织，请更改搜索条件重试。
  </div>
</template>

<script>
  import OrgIcon from './icon.vue'

  export default {
    components: {
      OrgIcon
    },
    inject: ['selector'],
    props: {
      level: {
        type: Number,
        default: 0
      },
      parentId: Number,
      searchText: String
    },
    data () {
      return {
        nodes: []
      }
    },
    watch: {
      searchText () {
        this.queryOrgNodes()
      }
    },
    mounted () {
      this.queryOrgNodes()
    },
    methods: {
      getFullName (node) {
        const fullNameArray = node.fullName.split('|')
        return fullNameArray.length > 1 ? fullNameArray.slice(1).join('|') : fullNameArray.join(' / ')
      },
      getName (node) {
        return node.shortName === node.name
          ? node.shortName
          : (node.shortName && node.name
            ? `${node.shortName}(${node.name})`
            : node.shortName || node.name)
      },
      async queryOrgNodes () {
        if (this.$searchTimer) clearTimeout(this.$searchTimer)

        this.$searchTimer = setTimeout(async () => {
          const data = this.searchText.trim()
            ? await this.$http.get({
              url: '/services/org-nodes',
              params: {
                preset: 'search',
                key: this.searchText,
                limit: 20
              }
            })
            : []
          if (data) this.nodes = data
        }, 500)
      },
      onNodeClick (node) {
        this.selector.onNodeClick(node)
      }
    }
  }
</script>
