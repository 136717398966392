<template>
  <mu-flex-box class="mc-page-header" align-items="center">
    <mu-icon v-if="icon" class="mc-application-logo" :icon="icon" />
    <img v-else class="mc-application-logo" :src="logo">
    <div class="mc-application-title">
      {{ title }}
    </div>
    <org-selector v-if="!isPortal" />
    <apps-menu trigger-action="click" />
    <download-menu />
    <letters-menu trigger-action="click" />
    <user-menu trigger-action="click" />
  </mu-flex-box>
</template>

<script>
  import OrgSelector from './org-selector/index.vue'
  import DownloadMenu from './download-menu.vue'
  import UserMenu from './user-menu.vue'
  import AppsMenu from './apps-menu.vue'
  import LettersMenu from './letters.vue'

  export default {
    name: 'McPageHeader',
    components: {
      OrgSelector,
      UserMenu,
      AppsMenu,
      DownloadMenu,
      LettersMenu
    },
    inject: ['context'],
    computed: {
      isPortal () {
        return this.$application.frameMode === 'portal'
      },
      title () {
        return this.$application.title || (
          this.isPortal
            ? this.context.portalName
            : this.context.applicationTitle
        )
      },
      icon () {
        return this.$application.logoIcon || undefined
      },
      logo () {
        return this.icon
          ? undefined
          : (
            this.$application.logo ||
            this.context.applicationLogo ||
            this.context.tenantLogo
          )
      }
    },
    mounted () {

    }
  }
</script>
