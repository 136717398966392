<template>
  <div ref="group" class="mc-icon-menu-group">
    <div
      ref="menuItemEl"
      class="mc-icon-menu-group_content"
      :selectable="selectable || null"
      :active="isActive || null"
      :hover="itemHover || null"
      @click="onClick"
      @mouseenter="onMenuItemMouseEnter"
      @mouseleave="onMenuItemMouseLeave">
      <slot name="content">
        <!-- <mu-icon
          v-if="icon"
          class="mc-icon-menu-item"
          :icon="icon"
          :icon-class="iconClass" /> -->
        <div
          class="mc-icon-menu-item">
          {{ title?.substring?.(0, 1) ?? '' }}
        </div>
      </slot>
    </div>
    <mc-submenu
      ref="subMenuEl"
      :parent-item="item"
      :items="children"
      @mouseenter.native="onSubMenuMouseEnter"
      @mouseleave.native="onSubMenuMouseLeave" />
  </div>
</template>

<script setup>
  import { ref, inject, computed, reactive, provide, getCurrentInstance } from 'vue'
  import delay from '@utils/delay'
  import McSubmenu from './submenu.vue'

  const props = defineProps({
    item: {
      type: Object,
      default: () => {}
    },
    selectable: Boolean,
    active: Boolean
  })

  const emits = defineEmits(['click', 'active:change'])

  const group = ref(null)
  const expanded = ref(false)
  const selected = ref(false)
  const itemHover = ref(false)
  const subMenuEl = ref()
  const menuItemEl = ref()
  const hoverTimer = ref(null)
  const submenuShow = ref(false)
  const submenuDirection = ref('down')

  const children = computed(() => props.item?.menus || [])
  const icon = computed(() => props.item?.icon || null)
  const iconClass = computed(() => props.item?.iconClass || null)
  const title = computed(() => props.item?.aliasName || props.item.name)

  const menu = inject('menu')
  const menuGroup = inject('menuGroup', null)
  const context = inject('context')
  const application = inject('application')

  const onClick = (event) => {
    if (!props.item.disabled && !isActive.value && props.item.url && !children.value.length) {
      application.redirectModuleUrl({
        newTab: event.ctrlKey || event.metaKey,
        moduleId: props.item.moduleId,
        moduleUrl: props.item.url
      })
    }
  }

  const isActive = computed(() => {
    return children.value.length
      ? children.value.some(child => child.active || child.moduleId === context.moduleId)
      : (props.item.active || props.item.moduleId === context.moduleId)
  })

  const clearHoverTimer = () => {
    if (hoverTimer.value) {
      clearTimeout(hoverTimer.value)
      delete hoverTimer.value
    }
  }

  const select = () => {
    if (menu?.selectMode === 'auto') {
      selected.value = true
      menu.setActiveItem(currentGroup)
    }
    if (!props.active) emits('active:change', true)
  }

  const unselect = () => {
    if (menu?.selectMode === 'auto') {
      selected.value = true
    }
    if (props.active) emits('active:change', false)
  }

  const onMenuItemMouseEnter = (e) => {
    if (props.item.disabled) return

    e.stopPropagation()
    itemHover.value = true
    submenuShow.value = true
    const submenuElement = subMenuEl.value?.$el
    clearHoverTimer()
    hideOtherSubMenus(submenuElement)
    if (submenuElement) {
      adjustSubmenuPosition()
      submenuElement.classList.add('mc-submenu-wrapper_show')
      menu.setCurrentHoverItem(menuItemEl.value)
    }
  }

  const onMenuItemMouseLeave = (e) => {
    e.stopPropagation()
    const toElement = e.relatedTarget
    const submenuElement = subMenuEl.value?.$el
    if (!(toElement && submenuElement && submenuElement.contains(toElement))) {
      itemHover.value = false
      submenuShow.value = false
      if (submenuElement) {
        clearHoverTimer()
        menu.setCurrentHoverItem(null)
        submenuElement.classList.remove('mc-submenu-wrapper_show')
        clearSubmenuStyle()
      }
    }
  }

  const onSubMenuMouseEnter = (e) => {
    if (props.item.disabled) return
    e.stopPropagation()
    itemHover.value = true
    submenuShow.value = true
    clearHoverTimer()
    if (subMenuEl.value?.$el) {
      subMenuEl.value.$el.classList.add('mc-submenu-wrapper_show')
    }
  }

  const onSubMenuMouseLeave = (e) => {
    e.stopPropagation()
    const toElement = e.relatedTarget
    itemHover.value = false
    submenuShow.value = false
    const currentHoverItem = menu.currentHoverItem
    if (subMenuEl.value?.$el && toElement !== currentHoverItem.value) {
      clearHoverTimer()
      hoverTimer.value = setTimeout(() => {
        menu.setCurrentHoverItem(null)
        subMenuEl.value.$el.classList.remove('mc-submenu-wrapper_show')
        clearSubmenuStyle()
      }, 300)
    }
  }

  const adjustSubmenuPosition = () => {
    const windowHeight = window.innerHeight

    const sidebarElement = menu.getElement()
    const sidebarRect = sidebarElement.getBoundingClientRect()
    const headerHeight = sidebarRect.top + 2

    const menuItemElement = menuItemEl.value
    const menuItemHeight = menuItemElement.clientHeight
    const menuItemRect = menuItemElement.getBoundingClientRect()
    const menuItemTop = menuItemRect.top
    const menuItemBottom = menuItemRect.bottom
    // // 考虑菜单按钮在视窗区域内显示不全的情况
    // const menuItemViewHeight =
    //   menuItemBottom > windowHeight
    //     ? (windowHeight - menuItemTop)
    //     : menuItemHeight

    const submenuElement = subMenuEl.value.$el
    const submenuHeight = submenuElement.clientHeight

    // 子菜单向下展开时, 视窗内高度
    const downHeight = windowHeight - menuItemTop
    // 子菜单向上展开时, 视窗内高度
    const upHeight = downHeight > menuItemHeight
      ? windowHeight - headerHeight - downHeight + menuItemHeight
      : windowHeight - headerHeight
    const direction = upHeight > downHeight && downHeight < submenuHeight ? 'up' : 'down'
    submenuDirection.value = direction

    if (direction === 'down') {
      submenuElement.style.top = `${menuItemTop.toFixed()}px`
      if (submenuHeight > downHeight) {
        submenuElement.style.height = `${downHeight.toFixed()}px`
        submenuElement.style.overflowY = 'auto'
      }
    }

    if (direction === 'up') {
      if (upHeight < submenuHeight) {
        submenuElement.style.height = `${upHeight.toFixed()}px`
        submenuElement.style.top = `${headerHeight.toFixed()}px`
        submenuElement.style.overflowY = 'auto'
      } else {
        submenuElement.style.top = `${(menuItemBottom - submenuHeight).toFixed()}px`
      }
    }
  }

  const clearSubmenuStyle = () => {
    const submenuElement = subMenuEl.value.$el
    submenuElement.style.top = ''
    submenuElement.style.height = ''
    submenuElement.style.overflow = ''
    submenuElement.scrollTop = 0
  }

  const hideOtherSubMenus = (submenuElement) => {
    const sidebarElement = menu.getElement()
    const submenuEls = sidebarElement.querySelectorAll('.mc-submenu-wrapper_show')
    submenuEls.forEach(el => {
      if (submenuElement && submenuElement !== el) {
        el.classList.remove('mc-submenu-wrapper_show')
      }
    })
  }

  const currentGroup = reactive({
    key: getCurrentInstance().uid,
    selected,
    select,
    unselect,
    expanded,
    menuGroup
  })

  provide('menuGroup', currentGroup)
</script>
