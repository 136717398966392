<template>
  <a
    :key="item.id"
    class="mc-menu-item mc-button-like"
    :disabled="disabled || null"
    :active="isActive || null"
    @click="onClick">
    <slot>
      <mu-icon :icon="item.icon || undefined" />
      {{ item.aliasName }}
    </slot>
  </a>
</template>

<script setup>
  import { computed, inject, onMounted } from 'vue'

  const context = inject('context')
  const application = inject('application')
  const menuGroup = inject('menuGroup', null)

  const props = defineProps({
    item: Object,
    disabled: Boolean
  })

  const isActive = computed(() => {
    return props.item.active || props.item.moduleId === context.moduleId
  })

  onMounted(() => {
    if (isActive.value) {
      if (menuGroup?.isExpander) menuGroup.expand()
      else if (menuGroup?.menuGroup?.isExpander) menuGroup.menuGroup.expand()
    }
  })

  const onClick = (event) => {
    if (!props.disabled && !isActive.value && props.item.url) {
      application.redirectModuleUrl({
        newTab: event.ctrlKey || event.metaKey,
        moduleId: props.item.moduleId,
        moduleUrl: props.item.url
      })
    }
  }

</script>
