import axios from 'axios'
import uniqueId from './unique-id'

const FileService = {}
const FileTypes = [
  'Blob',
  'File',
  'String',
  'Number',
  'Boolean',
  'Array',
  'Object'
]

function __getType (value) {
  return Object.prototype.toString
    .call(value)
    .replace('[object ', '')
    .replace(']', '')
}

function __isStringArray (param) {
  let r = __getType(param) === 'Array' && param.length > 0
  if (r) {
    for (let i = 0; i < param.length; i++) {
      r = param[i] && __getType(param[i]) === 'String'
      if (!r) {
        break
      }
    }
  }
  return r
}

function getErrorMessage (options) {
  const type = __getType(options.fileObject)
  if (!FileTypes.includes(type)) {
    return `${type} 类型数据无法上传`
  } else {
    return !options.key
      ? '上传内容必须指定 key 属性'
      : !options.product
          ? '上传内容必须指定 productType 属性'
          : ''
  }
}

function setFileType (options) {
  const type = __getType(options.fileObject)

  function setFileKey () {
    options.key = options.key || options.fileObject.name || ''
  }

  function setCommonFileObject () {
    options.fileObject = new window.Blob([options.fileObject + ''], {
      type: 'text/plain'
    })
  }

  function setObjFileObject () {
    options.fileObject = new window.Blob(
      [JSON.stringify(options.fileObject)],
      {
        type: 'text/plain'
      }
    )
  }

  const fileMap = {
    Blob: setFileKey,
    File: setFileKey,
    String: setCommonFileObject,
    Boolean: setCommonFileObject,
    Number: setCommonFileObject,
    Array: setObjFileObject,
    Object: setObjFileObject
  }

  fileMap[type]()
}

/**
 * 上传内容到oss
 *
 * Example:
 *
 *    window.FileService.upload({
 *        key: '',                        // 文件名称
 *        product: '',                // 产品类型
 *        moduleName: '',                 // 模块名称
 *        public: false,                // 是否公共文件
 *        tempFile: false,               // 是否存储为临时文件
 *        isAllowRepeated: false,         // 是否允许重名文件上传不覆盖
 *        fileObject: [File|Blob|String|Number|Boolean|Array|Object], // 上传内容
 *        success: function (data) {
 *            // data: {key: '', url: ''}
 *        },
 *        error: function (msg) { },
 *        onUploadProgress: function (e) {},
 *        onCancelToken: function (cancel) {},
 *    });
 *
 * @param {Object} options 参数对象
 * @returns
 */
// eslint-disable-next-line complexity
FileService.upload = async function (options = {}) {
  const {
    singleMaxSize = 2 * 1024 * 1024 * 1024,
    singleSignatureURL = '/fs/form-signature',
    key: fileKey = '',
    product = '',
    moduleName = '',
    onUploadProgress,
    onCancelToken
  } = options

  const forPublic = !!options.public
  const tempFile = !!options.tempFile
  const isAllowRepeated = !!options.isAllowRepeated
  const tenantIgnore = !!options.tenantIgnore
  const error = options.error || function () {}
  const success = options.success || function () {}

  let errorMsg = ''

  errorMsg = getErrorMessage(options)
  setFileType(options)

  if (errorMsg) {
    error(errorMsg)
    return FileService
  }

  if (options.fileObject.size > singleMaxSize) {
    console.error('文件内容过大，无法上传')
    error('文件内容过大，无法上传')
    return FileService
  }

  const postData = {
    keys: [moduleName === '' ? fileKey : moduleName + '/' + fileKey],
    product,
    tempFile,
    autoGenerated: isAllowRepeated,
    forPublic,
    tenantIgnore
  }
  try {
    const response = await axios.post(singleSignatureURL, postData)
    if (response.data) {
      const data = response.data[0]
      const url = data.postUrl
      const formData = new window.FormData()

      postData.savedKey = data.savedKey

      for (const key in data.formData) {
        formData.append(key, data.formData[key])
      }

      formData.append(
        'Content-Disposition',
        'attachment;filename=' + encodeURIComponent(options.key)
      )
      formData.append('file', options.fileObject)
      if (options.fileObject.type) {
        formData.append('Content-Type', options.fileObject.type)
      }
      try {
        await axios.post(url, formData, {
          cancelToken: new axios.CancelToken(c => {
            onCancelToken && onCancelToken(c)
          }),
          onUploadProgress: e => {
            onUploadProgress && onUploadProgress(e)
          }
        })

        success(postData)
      } catch (err) {
        error(err)
      }
    }
  } catch (err) {
    error(error)
  }

  return FileService
}

/**
 * 根据key获取下载地址
 *
 * Example:
 *
 *    window.FileService.get({
 *        keys: [''],                     // oss文件唯一标识数组
 *        key: '',                        // oss文件的唯一标识，测试采用key来进行测试，后续会更改成keys
 *        productType: '',                // 产品类型
 *        expires: 15,                    // 到期时间，默认值：15分钟
 *        tenantIgnore: false,            // 是否忽略租户
 *        success: function (data) {
 *            // data: [{key: '', url: ''}]
 *        },
 *        error: function (msg) { }
 *    });
 *
 * @param {Object} options 参数对象
 * @returns
 */
FileService.get = function (options = {}) {
  const {
    product = '',
    expires = 15,
    tempFile = !!options.tempFile,
    tenantIgnore = !!options.tenantIgnore,
    type = '',
    error,
    success,
    response = !!options.response,
    imgParameters = ['img', 'video'].includes(type) ? (options.imgParameters || '') : '' // 对图片做处理的参数，例如：'100h_100w_2e'
  } = options

  const accessesURL = options.accessesURL || '/fs/accesses'
  const keys = __getType(options.keys) !== 'Array' ? [options.keys] : options.keys

  let errorMsg = ''
  errorMsg = !__getType(keys) === 'Array'
    ? '获取内容地址时 keys 属性必须为非空数组'
    : !product
        ? '获取内容地址必须指定 product 属性'
        : ''

  if (errorMsg) {
    error && error(errorMsg)
    return FileService
  }

  const postData = !response
    ? {
        keys,
        product,
        expires,
        tempFile,
        type,
        imgParameters,
        tenantIgnore
      }
    : {
        product,
        tempFile,
        type,
        imgParameters,
        items: keys.map(item => {
          return {
            key: item.key,
            response: {
              'content-disposition': `attachment;filename=${encodeURI(item.fileName || item.key)}`
            }
          }
        }),
        tenantIgnore
      }

  axios
    .post(accessesURL, postData)
    .then(res => {
      if (res.data) {
        success && success(res.data, res)
      } else {
        console.warn('没有对应的资源内容')
      }
    })
    .catch(err => {
      error && error(err)
    })

  return FileService
}

/**
 * 删除文件
 *
 * Example:
 *
 *    window.FileService.delete({
 *        keys: [''],                     // oss文件唯一标识数组
 *        product: '',                // 产品类型
 *        success: function () { },
 *        error: function (msg) { }
 *    });
 *
 * @param {Object} options 参数对象
 * @returns
 */

FileService.delete = function (options = {}) {
  const {
    product = '',
    tenantIgnore = !!options.tenantIgnore,
    error,
    success,
    deleteURL = '/fs/file-objects'
  } = options

  const keys = __getType(options.keys) !== 'Array' ? [options.keys] : options.keys

  let errorMsg = ''
  errorMsg = !__isStringArray(keys)
    ? '删除内容时 keys 属性必须为非空字符串数组'
    : !product
        ? '删除内容必须指定 product 属性'
        : ''

  if (errorMsg) {
    error && error(errorMsg)
  } else {
    const postData = {
      keys,
      product,
      tenantIgnore: !!tenantIgnore
    }
    axios
      .patch(deleteURL, postData)
      .then(res => {
        if (res.data) {
          success && success(res.data, res)
        } else {
          console.warn('没有对应的资源内容')
        }
      })
      .catch(err => {
        error && error(err)
      })
  }
  return FileService
}

async function uploadFile (options) {
  return await FileService.upload(options)
}

function getFiles (options) {
  return FileService.get(options)
}

function deleteFile (options) {
  return FileService.delete(options)
}

function getFileBaseInfo (file, userId) {
  const arr = file.name?.split('.') || ['']

  const { fileName, extName } = arr.length > 1
    ? {
        fileName: arr.slice(0, -1).join('.'),
        extName: arr.slice(-1)[0].toLowerCase()
      }
    : {
        fileName: file.name,
        extName: ''
      }

  const fileId = file.id || file.fileId || uniqueId()
  const { year, month, date } = getCurrentDate()
  const prefix = `${year}/${month}/${date}`
  const fileInfo = {
    id: fileId,
    fileName,
    extName,
    fileKey: userId ? `${prefix}/${userId}/${fileId}.${extName}` : `${prefix}/${fileId}.${extName}`
  }

  return fileInfo
}

function getCurrentDate () {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const date = currentDate.getDate()

  return { year, month, date }
}

export { uploadFile, getFiles, deleteFile, getFileBaseInfo, getCurrentDate }
