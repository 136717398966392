import { getFiles } from './file-service'

function _getFiles (keys, parameters, type, product, tenantIgnore = false, accessesURL = null) {
  if (!keys || keys.length === 0) {
    return Promise.resolve()
  }
  return new Promise((resolve, reject) => {
    getFiles({
      keys,
      product,
      expires: 120,
      type,
      imgParameters: parameters,
      tenantIgnore,
      accessesURL,
      success: (data, response) => {
        resolve(data)
      },
      error: error => {
        reject(error)
      }
    })
  })
}

function _getFilesWithFileName (
  keys, parameters, type, product, tenantIgnore = false, accessesURL = null
) {
  if (!keys || keys.length === 0) {
    return Promise.resolve()
  }
  return new Promise((resolve, reject) => {
    getFiles({
      keys,
      product,
      expires: 120,
      response: true,
      type,
      tenantIgnore,
      imgParameters: parameters,
      accessesURL,
      success: (data, response) => {
        resolve(data)
      },
      error: error => {
        reject(error)
      }
    })
  })
}

function bulkGetUrls (medias, options) {
  // 需要保证medias的对象结构包含type : image video audio, key: key
  // options 可以传递width， product， 如果没有width参数是不会获取thumbnail
  // 方法会返回对应
  // {
  //   key: {
  //     url: '',
  //     thumbnail: '', // 如果是image则会返回宽度为options里面width的缩略图，如果是视频则返回第一帧的缩略图
  //   }
  // }
  const cloneImages = medias.map(i => ({ ...i }))
  const imageKeys = []
  const audioKeys = []
  const videoKeys = []
  let imageParameters = ''
  let videoParameters = ''
  if (options.width) {
    imageParameters = `image/resize,w_${options.width}`
    videoParameters = `video/snapshot,t_0,f_jpg,w_${options.width},m_fast`
  }
  if (options.rotate) {
    imageParameters += (options.width ? '/' : 'image/') + `rotate,${options.rotate}`
  }
  const tenantIgnore = !!options.tenantIgnore
  cloneImages.forEach(media => {
    if (media.type === 'image') imageKeys.push(media.key)
    else if (media.type === 'audio') audioKeys.push(media.key)
    else if (media.type === 'video') videoKeys.push(media.key)
  })
  const accessesURL = options.accessesURL || null
  return new Promise((resolve, reject) => {
    if (imageKeys.length > 0 || audioKeys.length > 0 || videoKeys.length > 0) {
      if (imageParameters || videoParameters) {
        Promise.all([
          _getFiles(imageKeys, '', 'img', options.product, tenantIgnore, accessesURL),
          _getFiles(imageKeys, imageParameters, 'img', options.product, tenantIgnore, accessesURL),
          _getFiles(videoKeys, '', 'video', options.product, tenantIgnore, accessesURL),
          _getFiles(videoKeys, videoParameters, 'video', options.product, tenantIgnore, accessesURL),
          _getFiles(audioKeys, '', 'audio', options.product, tenantIgnore, accessesURL)
        ]).then(([imageData, imageThumbData, videoData, videoThumbData, audioData]) => {
          const result = {}
          medias.forEach(media => {
            if (media.type === 'image') {
              result[media.key] = {
                url: imageData[media.key],
                thumbnail: imageThumbData[media.key]
              }
            } else if (media.type === 'video') {
              result[media.key] = {
                url: videoData[media.key],
                thumbnail: videoThumbData[media.key]
              }
            } else if (media.type === 'audio') {
              result[media.key] = {
                url: audioData[media.key]
              }
            }
          })
          resolve(result)
        })
      } else {
        Promise.all([
          _getFiles(imageKeys, '', 'img', options.product, tenantIgnore, accessesURL),
          _getFiles(videoKeys, '', 'video', options.product, tenantIgnore, accessesURL),
          _getFiles(audioKeys, '', 'audio', options.product, tenantIgnore, accessesURL)
        ]).then(([imageData, videoData, audioData]) => {
          const result = {}
          medias.forEach(media => {
            if (media.type === 'image') {
              result[media.key] = {
                url: imageData[media.key]
              }
            } else if (media.type === 'video') {
              result[media.key] = {
                url: videoData[media.key]
              }
            } else if (media.type === 'audio') {
              result[media.key] = {
                url: audioData[media.key]
              }
            }
          })
          resolve(result)
        })
      }
    } else {
      resolve('')
    }
  })
}

function getUrl (key, product, tenantIgnore = false, accessesURL = null, type = 'img') {
  return new Promise((resolve, reject) => {
    _getFiles([key], '', type, product, tenantIgnore, accessesURL).then(data => {
      if (data && data[key]) {
        resolve({ url: data[key] })
      }
      resolve({ url: '' })
    }).catch(error => {
      reject(error)
    })
  })
}

function getUrlWithFileName (key, product, fileName, tenantIgnore = false, accessesURL = null) {
  return new Promise((resolve, reject) => {
    _getFilesWithFileName([{ key, fileName }], '', 'img', product, tenantIgnore, accessesURL).then(data => {
      if (data && data[key]) {
        resolve({ url: data[key] })
      }
      resolve({ url: '' })
    }).catch(error => {
      reject(error)
    })
  })
}

export {
  bulkGetUrls,
  getUrl,
  getUrlWithFileName
}
