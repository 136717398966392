<template>
  <div ref="group" class="mc-menu-group" :expanded="expanded || null">
    <div
      class="mc-menu-group_header mc-button-like"
      :selectable="selectable || null"
      :active="isActive || null"
      @click="onClick">
      <slot name="header">
        <mu-icon
          :icon="icon"
          :icon-class="iconClass" />
        {{ title }}
      </slot>
      <mu-icon
        v-if="isExpander || isDropdown"
        icon="menuGroupCollapsed"
        :expanded="expanded || null"
        @click.stop="onIconClick" />
    </div>
    <div class="mc-menu-group_body" :style="{ height: wrapperHeight }">
      <div ref="wrapper" class="mu-box" layout="flex" direction="column">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, computed, provide, inject, watch, onMounted, getCurrentInstance } from 'vue'
  import delay from '@utils/delay'

  const props = defineProps({
    icon: String,
    iconClass: String,
    title: String,
    selectable: Boolean,
    active: Boolean
  })
  const emits = defineEmits(['click', 'active:change'])

  const group = ref(null)
  const wrapper = ref(null)
  const expanded = ref(false)
  const selected = ref(false)
  const wrapperHeight = ref(0)

  const menu = inject('menu')
  const menuGroup = inject('menuGroup', null)

  const isActive = computed(() => {
    return menu.selectMode === 'auto' ? selected.value : props.active
  })

  const isExpander = computed(() => {
    return menu && !menuGroup
  })

  const isDropdown = computed(() => {
    return !menu && !menuGroup
  })

  const expandedSibling = computed(() => {
    return (menu?.accordion && isExpander.value)
      ? menu.expandedGroup
      : null
  })

  watch(() => expandedSibling.value, (v) => {
    if (v && v !== currentGroup && expanded.value) collapse()
  })

  watch(() => props.active, (v) => {
    if (!v === !selected.value) return
    if (v) select()
    else unselect()
  }, { immediate: true })

  onMounted(() => {
    wrapperHeight.value = isExpander.value && !expanded.value ? 0 : undefined
  })

  const onClick = () => {
    if (props.selectable) {
      emits('click')
      select()
    } else if (isExpander.value) {
      toggleExpand()
    }
  }

  const onIconClick = () => {
    if (isExpander.value) toggleExpand()
  }

  const expand = () => {
    expanded.value = true
    menu.setExpandedGroup(currentGroup)
    wrapperHeight.value = `${wrapper.value.offsetHeight}px`
  }

  const collapse = () => {
    if (wrapperHeight.value === undefined) {
      wrapperHeight.value = `${wrapper.value.offsetHeight}px`
    }
    delay(1).then(() => {
      expanded.value = false
      wrapperHeight.value = 0
    })
  }

  const toggleExpand = () => {
    return expanded.value ? collapse() : expand()
  }

  const select = () => {
    if (menu?.selectMode === 'auto') {
      selected.value = true
      menu.setActiveItem(currentGroup)
    }
    if (!props.active) emits('active:change', true)
  }

  const unselect = () => {
    if (menu?.selectMode === 'auto') {
      selected.value = true
    }
    if (props.active) emits('active:change', false)
  }

  const currentGroup = reactive({
    key: getCurrentInstance().uid,
    isExpander,
    selected,
    select,
    unselect,
    expanded,
    expand,
    menuGroup
  })

  provide('menuGroup', currentGroup)

</script>
