import Vue from 'vue'
import * as mussel from 'mussel'

import './pcss'

import Page from './page/index.vue'
import PageBreadcrumb from './page/breadcrumb.vue'
import OrgTreeNode from './page/header/org-selector/tree-node.vue'
import OrgTreeNodes from './page/header/org-selector/tree-nodes.vue'
import DlgFeedback from './page/header/dialog-send-feedback.vue'
import DlgComment from './page/header/dialog-send-comment.vue'

import { install as installCommonUI } from '@mctech/vue-common-ui'

import './icons'

import {
  http,
  query,
  blockingAnimation,
  showSuccessMessage,
  showBizErrorMessage,
  showFatalErrorMessage
} from './utils'

if (Vue) {
  Vue.prototype.$http = http

  Vue.component('McPage', Page)
  Vue.component('McPageBreadcrumb', PageBreadcrumb)
  Vue.component('McOrgTreeNode', OrgTreeNode)
  Vue.component('McOrgTreeNodes', OrgTreeNodes)
  Vue.component('DlgFeedback', DlgFeedback)
  Vue.component('DlgComment', DlgComment)

  installCommonUI(Vue)
}

const theme = mussel?.variables

export {
  Page,
  query,
  theme,
  blockingAnimation,
  showSuccessMessage,
  showBizErrorMessage,
  showFatalErrorMessage
}
