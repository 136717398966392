<template>
  <div
    class="mu-dropdown mc-page-header_button"
    trigger-action="click"
    :expanded="popupParams.visible"
    :notification="!notifiedMXZFriend || !notifiedMXZToolbox"
    style="width: auto; padding: 0 16px;"
    @click="onClick">
    <!-- <mu-icon icon="user" /> -->
    <span style="font-size: 1.17rem">{{ context.userName }}</span>
    <mu-dropdown-panel
      v-show="!disabled && popupParams.visible"
      class="mc-page-user-menu_dropdown"
      v-bind="popupParams"
      @change="setPopupVisible"
      @click.native="hidePopup">
      <div
        class="mu-list-group"
        @click.stop>
        <mu-dropdown-item
          v-for="(item, idx) in context.extraMenus"
          :key="idx"
          :icon="item.icon"
          :label="item.caption"
          @click="onExtraMenuClick(item)" />
        <mu-list-divider v-if="context.extraMenus.length" />
        <mu-dropdown-item
          class="mc-page_sys-menu-item"
          icon="command"
          :notification="!notifiedMXZFriend"
          @click="friendMXZ">
          绑定梦小智
        </mu-dropdown-item>
        <mu-dropdown-item
          class="mc-page_sys-menu-item"
          icon="command"
          :notification="!notifiedMXZToolbox"
          @click="IntroMXZToolbox">
          梦小智工具箱
        </mu-dropdown-item>
        <mu-list-divider />
        <mu-dropdown-item icon="send" @click="sendFeedback">
          发送反馈
        </mu-dropdown-item>
        <mu-dropdown-item icon="mailBox" @click="gotoMyFeedbackCenter">
          我的反馈
        </mu-dropdown-item>
        <mu-list-divider />
        <!-- <mu-dropdown-item icon="pm" @click="showApp">
          扫码下载 App
        </mu-dropdown-item> -->
        <mu-dropdown-item
          v-if="!isCustomer"
          icon="pm"
          @click="showOTP">
          OTP 二维码
        </mu-dropdown-item>
        <mu-dropdown-item icon="password" @click="changePassword">
          更改密码
        </mu-dropdown-item>
        <mu-dropdown-item
          v-if="context.multiTenant"
          icon="org-company-root"
          @click="changeTenant">
          切换租户
        </mu-dropdown-item>
        <mu-dropdown-item icon="logout" danger @click="logout">
          {{ logoutText }}
        </mu-dropdown-item>
      </div>
    </mu-dropdown-panel>
    <mu-dialog
      v-model="appcodeDialogVisible"
      title="扫码下载App"
      mask-action="none"
      :buttons="['关闭']"
      @buttonclick="appcodeDialogVisible = false">
      <img
        v-if="appcodeLoaded"
        :src="appQrCode"
        style="width: 260px; height: 260px;">
    </mu-dialog>
    <mu-dialog
      v-model="qrcodeDialogVisible"
      title="OTP二维码"
      mask-action="none"
      :buttons="['关闭']"
      @buttonclick="qrcodeDialogVisible = false">
      <img
        v-if="qrcodeLoaded"
        :src="otpQrCode"
        style="width: 260px; height: 260px;">
    </mu-dialog>
    <dlg-feedback
      ref="dlgSendFeedback"
      dlg-title="发送反馈"
      :feedback-info="feedbackInfo"
      :show-screenshot="true"
      @save="saveFeedBack" />
  </div>
</template>

<script>
  import { Dropdown } from 'mussel'
  import { getLocalState, setLocalState } from '../../utils/local-store'

  export default {
    name: 'McPageUserMenu',
    extends: Dropdown,
    inject: ['context'],
    data () {
      return {
        qrcodeLoaded: false,
        qrcodeDialogVisible: false,
        appcodeLoaded: false,
        appcodeDialogVisible: false,
        appQrCode: '/services/public/app-qrcode',
        otpQrCode: '/services/otp-qrcode',
        notifiedMXZFriend: !!getLocalState('MCTECH_MXZ_FRIEND_MENU_NOTIFIED'),
        notifiedMXZToolbox: !!getLocalState('MCTECH_MXZ_TOOLBOX_MENU_NOTIFIED'),
        feedbackInfo: {}
      }
    },
    computed: {
      logoutText () {
        return this.$application.logoutText || '退出登录'
      },
      isCustomer () {
        return this.context.userSource === 'customer'
      }
    },
    methods: {
      showApp () {
        this.appcodeDialogVisible = true
        this.appcodeLoaded = true
      },
      showOTP () {
        this.qrcodeDialogVisible = true
        this.qrcodeLoaded = true
      },
      friendMXZ () {
        setLocalState('MCTECH_MXZ_FRIEND_MENU_NOTIFIED', '1')
        this.notifiedMXZFriend = true
        window.open('/friend-mxz.html', 'MctechFriendMXZWindow')
      },
      IntroMXZToolbox () {
        setLocalState('MCTECH_MXZ_TOOLBOX_MENU_NOTIFIED', '1')
        this.notifiedMXZToolbox = true
        window.open('https://ai.mctech.vip', 'MctechMXZToolboxWindow')
      },
      redirect (task) {
        this.$http.post('/services/get-module-by-url', { orgId: task.orgId, url: task.moduleUrl }).then(data => {
          window.open(`${task.moduleUrl}${task.moduleUrl.indexOf('?') > -1 ? '&' : '?'}applicationId=${Number(data.applicationId).toString(36)}&orgId=${Number(data.orgId).toString(36)}&frame=n&bizId=${task.bizId}`)
        })
      },
      sendFeedback () {
        const currentUrl = new URL(window.location.href)
        this.feedbackInfo = Object.assign({}, {
          tenantId: this.context.tenantId ?? null,
          orgId: this.context.orgId ?? null,
          applicationId: this.context.applicationId ?? null,
          moduleId: this.context.moduleId || null,
          moduleName: this.context.moduleName || '',
          menuName: this.context.menuName || '',
          url: currentUrl.pathname + currentUrl.search,
          content: '',
          attachments: [],
          userPhone: this.context.phoneNumber || '',
          userEmail: ''
        })
        this.$refs.dlgSendFeedback.show()
      },
      async saveFeedBack (data) {
        const attachments = data.attachments.map(a => ({
          name: a.name,
          key: a.key,
          size: a.size,
          type: a.type
        }))
        await this.$http.post({
          url: '/services/feedbacks',
          data: {
            ...data,
            userPhone: data.userPhone || null,
            userEmail: data.userEmail || null,
            attachments
          },
          promptBizException: false,
          promptFatalException: false
        })
      },
      gotoMyFeedbackCenter () {
        window.open('/feedback.html')
      },
      changePassword () {
        window.open('/password.html')
      },
      logout () {
        if (this.$application.logoutClose) {
          window.close()
        } else {
          window.location.href = `/logout${this.$application.logoutService ? `?service=${this.$application.logoutService}` : ''}`
        }
      },
      changeTenant () {
        window.location.href = '/tenants.html'
      },
      onExtraMenuClick (item) {
        this.$application.redirectUrl({
          url: item.url
        })
      }
    }
  }
</script>
