<template>
  <div
    class="mu-dropdown mc-page-header_button"
    trigger-action="click"
    :expanded="popupParams.visible"
    @click="refreshData">
    <div class="letter-icon">
      <mu-icon
        icon="bell" />
      <span
        v-if="lettersLength !== 0"
        class="mu-badge empty"
        badge-type="danger" />
      <span
        v-if="lettersLength !== 0"
        class="mu-badge not-empty"
        badge-type="danger">
        {{ lettersLength }}
      </span>
    </div>
    <mu-dropdown-panel
      v-show="!disabled && popupParams.visible"
      class="mc-page-letters_dropdown"
      overflow="none"
      v-bind="popupParams"
      @change="setPopupVisible"
      @click.native="hidePopup">
      <div
        v-mu-scrollbar
        class="mu-list-group"
        @click.stop>
        <mu-list-item
          class="mu-flex-box"
          align-items="center"
          style="font-size: 1.17rem"
          disabled>
          <span size="1">{{ todoList.length ? '' : '当前没有' }}待办任务</span>
          <mu-button
            style="margin-left: 16px"
            button-style="outline"
            button-type="primary"
            icon="run"
            @click="toTaskCenter">
            任务中心
          </mu-button>
        </mu-list-item>
        <div v-if="todoList.length">
          <div v-for="(item, idx) in todoList" :key="idx">
            <mu-h-box
              class="mc-page-todo-list_item"
              content-spacing
              @click.native="redirect(item)">
              <div
                class="mc-page-todo-list_badge mu-flex-box"
                align-items="center"
                justify-content="center"
                :style="{borderColor: getBorderColor(item)}"
                :class="getTaskBookmarkColor(item)">
                {{ getTaskStatusName(item) }}
              </div>
              <mu-v-box class="mu-text-color-weak">
                <div style="padding-bottom:8px">
                  {{ getTaskStartDate(item.createdAt) }}
                </div>
                <div>{{ getTaskStartTime(item.createdAt) }}</div>
              </mu-v-box>
              <div
                size="1"
                :title="item.taskName"
                class="mc-page-todo-list_name">
                {{ item.taskName }}
              </div>
            </mu-h-box>
          </div>
        </div>
        <mu-list-divider v-if="todoList.length" />
        <mu-list-item style="font-size: 1.17rem" disabled>
          {{ messages.length ? '' : '当前没有' }}消息通知
        </mu-list-item>
        <div class="mc-page-message-list">
          <div v-for="(item, idx) in messages" :key="idx">
            <div class="mu-text-subtitle">
              {{ item.source }}
            </div>
            <div
              class="mc-page-message-list_item-content"
              style="
                display: -webkit-box;

                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;"
              :title="item.message.length > 50 ? item.message : undefined">
              {{ item.message }}
            </div>
          </div>
        </div>
      </div>
    </mu-dropdown-panel>
  </div>
</template>

<script>
  import { Dropdown } from 'mussel'
  import dayjs from 'dayjs'

  export default {
    name: 'McPageLettersMenu',
    extends: Dropdown,
    inject: ['context'],
    data () {
      return {
        messages: [],
        todoList: [],
        isWorkflowRegistered: false,
        lastClickTime: null
      }
    },
    computed: {
      lettersLength () {
        return this.messages.length + this.todoList.length
      }
    },
    watch: {
      context: {
        handler: function (value) {
          // this.loadTodoList()
        },
        deep: true,
        immediate: true
      }
    },
    mounted () {
      // this.isWorkflowRegistered = !!this.context.workflowEngine
      this.loadMessages()
      // if (this.isWorkflowRegistered) {
      this.loadTodoList()
      // }
    },
    methods: {
      async refreshData () {
        const currentTime = dayjs()
        if (
          !this.lastClickTime ||
          currentTime.diff(this.lastClickTime) > 30 * 1000
        ) {
          this.lastClickTime = currentTime
          await this.loadTodoList()
        }
        this.onClick(...arguments)
      },
      async loadMessages () {
        if (this.messages.length) return
        const data = await this.$application.loadMessages()
        if (data) this.messages = data
      },
      loadTodoList () {
        this.$application.loadTodoList()
          .then(data => {
            if (data) this.todoList = data.tasks
          })
      },
      redirect (task) {
        this.$application.redirectUrl({
          url: '/workflow-task/task-center.html',
          newTab: true,
          query: {
            taskId: task.taskId,
            processInstanceId: task.processInstanceId
          }
        })
      },
      toTaskCenter () {
        this.$application.redirectModuleUrl({
          moduleUrl: '/workflow-task/task-center.html',
          newTab: true
        })
      },
      getTaskStartDate (date) {
        if (date) {
          return dayjs(date).format('YY-MM-DD')
        }
        return ''
      },
      getTaskStartTime (date) {
        if (date) {
          return dayjs(date).format('HH:mm:ss')
        }
        return ''
      },
      getTaskBookmarkColor (item) {
        if (item.sourceAction === 'reject') {
          return 'mu-text-color-danger'
        }
        if (item.taskStatus === 'waiting') {
          return 'mu-text-color-warning'
        }
        return 'mu-text-color-primary'
      },
      getTaskStatusName (item) {
        if (item.sourceAction === 'reject') {
          return '退'
        }
        if (item.taskStatus === 'waiting') {
          return '抢'
        }
        return '办'
      },
      getBorderColor (item) {
        if (item.sourceAction === 'reject') {
          return '#f57a79'
        }
        if (item.taskStatus === 'waiting') {
          return '#faad14'
        }
        return '#008cd6'
      }
    }
  }
</script>
